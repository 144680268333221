
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ContextColorProvider } from '@warrenbrasil/nebraska-web';
import { HomeTemplate } from '@/modules/home/components/HomeTemplate';
import { HomeIllustration } from '@/modules/home/components/HomeIllustration';
import { HomeNews } from '@/modules/home/components/HomeNews';
import { HomeWidgets } from '@/modules/home/components/HomeWidgets';
import { CerberusTutorialModal } from '@/modules/home/components/CerberusTutorialModal';
import { HomeNotifications } from '@/modules/home/components/HomeNotifications';
import { HomeCash } from '@/modules/home/components/HomeCash';
import { ModalSuggestions as PensionModalSuggestions } from '@/modules/pension/components/ModalSuggestions';
import { clearNextRoutes } from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { UIModule } from '@/modules/common/store/UIModule';
import { WarrenEqualsModal } from '@/modules/home/components/WarrenEqualsModal';
import * as trackEvents from './track-events';

import { HomeCES } from '@/modules/home/components/HomeCES';

import {
  getBalances,
  BalancesResponse,
  IBalancesImages
} from '@/modules/home/services/bff';
import HomeBalanceSummary from '@/modules/home/components/HomeBalanceSummary/HomeBalanceSummary.vue';
import HomeAccountStatus from '@/modules/home/components/HomeAccountStatus/HomeAccountStatus.vue';
import { AccountStates } from '@/modules/common/services/http/account';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { FeatureFlags } from '@/types/models/FeatureFlags';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    ContextColorProvider,
    HomeTemplate,
    HomeIllustration,
    HomeNews,
    HomeWidgets,
    HomeCash,
    PensionModalSuggestions,
    WarrenEqualsModal,
    HomeCES,
    HomeBalanceSummary,
    HomeNotifications,
    HomeAccountStatus,
    CerberusTutorialModal
  }
})
export default class Home extends Vue {
  balanceDetails: Reactive<BalancesResponse> = null;
  images: Reactive<IBalancesImages> = null;

  isLoading = false;
  hasError = false;

  isCerberusModalOpen = false;

  @CustomerModule.Getter('isRegulatoryStatusBlocked')
  readonly isOverdue!: boolean;

  @CustomerModule.Getter('getCustomerId')
  readonly customerApiId!: string;

  @CustomerModule.Getter('getAccountStatus')
  readonly accountStatusResponse!: AccountStates;

  @ProvideReactive()
  get shouldTriggerHomeAnimations() {
    return !UIModule.getIsOpenWelcomeCurtain;
  }

  get showInvestmentsWidgets() {
    return this.accountStatusResponse.clientEnabled;
  }

  created() {
    this.load();
  }

  mounted() {
    trackEvents.trackHomePageView();
    clearNextRoutes();

    const hasOpenedCerberusModal = localStorage.getItem(
      StorageKeys.HasOpenedCerberusModal
    );

    if (this.isCerberusFFEnabled && hasOpenedCerberusModal !== 'true') {
      this.isCerberusModalOpen = true;
    }
  }

  private get isCerberusFFEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.ENABLE_CERBERUS_WELCOME);
  }

  reloadBalanceSummary() {
    this.load();
    trackEvents.trackReloadWorthErrorClick();
  }

  closeCerberusModal() {
    this.isCerberusModalOpen = false;
  }

  async load() {
    this.isLoading = true;
    this.hasError = false;

    try {
      await this.loadHeroBalance();
    } catch {
      this.hasError = true;
      trackEvents.trackWorthErrorLoad();
    } finally {
      this.isLoading = false;
    }
  }

  private async loadHeroBalance() {
    this.balanceDetails = await getBalances();
  }
}
