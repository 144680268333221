import { render, staticRenderFns } from "./DeallocateLimitsModal.vue?vue&type=template&id=e0d84d00&scoped=true&"
import script from "./DeallocateLimitsModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DeallocateLimitsModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DeallocateLimitsModal.vue?vue&type=style&index=0&id=e0d84d00&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0d84d00",
  null
  
)

export default component.exports