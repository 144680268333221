import { RouteConfig } from 'vue-router';
import SuitabilityProfile from '@/modules/onboarding/suitability-profile/views/SuitabilityProfile/SuitabilityProfile.vue';
import SignUp from '@/modules/onboarding/signup/views/SignUp/SignUp.vue';
import { logOffUser } from '@/modules/common/routes/navigation-guards/logOffUser';

export const onboardingRoutes: RouteConfig[] = [
  {
    name: 'signup',
    path: '/signup',
    beforeEnter: logOffUser,
    component: SignUp,
    meta: {
      context: 'onboarding',
      requiresAuth: false
    }
  },
  {
    name: 'myprofile',
    path: '/suitability/my-profile',
    component: SuitabilityProfile,
    meta: {
      context: 'onboarding',
      requiresAuth: false
    }
  }
];
