export interface IBFFNumberResponse {
  value: number;
  formatted: string;
}

interface IBFFBooleanResponse {
  value: boolean;
  formatted: string;
}

interface IInvestmentWindow {
  start: string;
  end: string;
}

export interface IPortfolioCatalog {
  productApiId: string;
  name: string;
  description: string;
  minimumAmount: IBFFNumberResponse;
  financialIncrement?: IBFFNumberResponse;
  available: IBFFBooleanResponse;
  investmentWindow?: IInvestmentWindow;
  valueToInvest?: number;
}

export interface IGetPortfolioCatalogResponse {
  portfolio: {
    name: string;
    isCustom: boolean;
  };
  catalog: {
    products: IPortfolioCatalog[];
  };
}

export interface IPortfolioInvestmentOptionsData {
  id: string;
  title: string;
  subTitle: string;
}

export interface IPortfolioInvestmentSuccessResponse {
  portfolioName: string;
  portfolioApiId: string;
  investment: {
    total: IBFFNumberResponse;
    dependentOnRisks?: IDependentOnRisks[];
    dependentOnTerms?: IDependentOnTerms[];
  };
}

export interface IPortfolioInvestmentSuccessRequest {
  requestApiId: string;
  portfolioApiId: string;
  customerApiId: string;
  products: { productApiId: string; amount: number }[];
  partnerApiId?: string;
}

export interface IGetAccountBalance {
  summary: {
    totalBalance: {
      value: number;
      formatted: string;
    };
  };
}

export interface IPortfolio {
  background: {
    thumb: string;
    small: string;
    regular: string;
    full: string;
    raw: string;
  };
  canEditPortfolio: boolean;
  showSuggestedModal: boolean;
  showManagementIcon?: boolean;
  id: string;
  name: string;
  goalAmount?: number;
  createdAt: string;
  goal: string;
  investmentOptions: string[];
  totalGrossWorth: IBFFNumberResponse;
  totalEarnings: number;
  isCustom: boolean;
  totalBalance: IBFFNumberResponse;
  canInvestInPortfolio: boolean;
}

export interface IDependentOnRisks {
  apiId: string;
  name: string;
  riskScore: number;
  riskGrade: string;
}

export interface IDdocuments {
  title: string;
  description: string;
  url: string;
}

export interface IDependentOnTerms {
  apiId: string;
  documents: IDdocuments[];
  name: string;
}

export interface IInstrumentValidation {
  dependentOnRisks?: IDependentOnRisks[];
  dependentOnTerms?: IDependentOnTerms[];
}

export interface IInstrumentValidationResponse {
  instrumentValidation: IInstrumentValidation;
}

export interface IExperience {
  additionalInformation?: {
    portfolioId: string;
  };
  experience: {
    active: boolean;
    button: string;
    formId: string;
    name: string;
    nameurl: string;
    subtitle: string;
    title: string;
    _id: string;
  };
  idCustomer: string;
  idExperience: string;
  isUsed: boolean;
  _id: string;
}

export interface IExperienceData {
  data: IExperience[];
}

export interface getCustomerExperienceResponse {
  getExperiences: IExperienceData;
}

export interface IUpdateCustomerExperiencePayload {
  name: string;
  customerId: string;
}

export interface IUpdateCustomerExperienceResponse {
  _id: string;
  experienceId: string;
  customerId: string;
  isUsed: string;
}

export interface IGetInvestmentSuggestionData {
  data: IInvestmentSuggestion[];
  requestTerms: boolean;
  requestRisks: boolean;
}

export interface IGetInvestmentSuggestionResponse {
  getInvestmentSuggestions: IGetInvestmentSuggestionData;
}

export interface IGetInstrumentProductValidationResponse {
  investmentProductValidation: {
    requestTerms: boolean;
    requestRisks: boolean;
  };
}

export enum IGetInstrumentProductValidationErrorTypes {
  OutsideOperationTime = 'InstrumentOutsideOperationTime',
  InvestmentInvalid = 'InstrumentUnavailable'
}

export interface IGetInstrumentProductValidationError {
  error: {
    key: string;
    code: number;
    message: {
      value: IGetInstrumentProductValidationErrorTypes;
      formatted: string;
    };
  };
}

export interface IInvestmentSuggestion {
  apiId: string;
  customerApiId: string;
  toPortfolioApiId: string;
  portfolioName: string;
  transactionId: string;
  amount: IBFFNumberResponse;
  products: IInvestmentSuggestionProduct[];
  requestTerms: boolean;
  requestRisks: boolean;
  name?: string;
  createdAt?: string;
}

export interface IInvestmentSuggestionProduct {
  productApiId: string;
  productName: string;
  amount: IBFFNumberResponse;
}

export interface IInvestmentAgreementPayload {
  transactionId: string;
  customerId: string;
  status: EInvestmentAgreementStatus;
  updatedBy: string;
}

export enum EInvestmentAgreementStatus {
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export interface IAllocation {
  apiId: string;
  name: string;
  type: ILabel;
  subType: string;
  value: IAllocValue;
  isLocked: boolean;
  percent: IAllocValue;
  liquidity: IAllocValue;
  minimumFinancialMovement: IAllocValue;
  performance?: IAllocValue;
  totalBalance?: IAllocValue;
  allocValue?: IAllocValue;
}

export interface ILabel {
  value: string;
  formatted: string;
}

export interface IAllocValue {
  value: number;
  formatted: string;
}
export interface ISubType {
  name: string;
  allocations: IAllocation[];
}

export interface IData {
  label: ILabel;
  allocValue: IAllocValue;
  subType: ISubType[];
}

export interface IPortfolioSummary {
  name: string;
  expirationTime: IBFFNumberResponse;
  minimumDeposit: number;
}
export interface IAppliedAllocations {
  data: IData[];
  portfolioLiquidity: string;
  portfolioSummary: IPortfolioSummary[];
  productsMinimumDeposit: number;
  lastUpdateAt: string;
}

export interface IPlannedAllocations {
  data: IData[];
}

export interface IWealthPortfolio {
  canEditPortfolio: boolean;
  showSuggestedModal: boolean;
  id: string;
  name: string;
  totalEarnings: number;
  totalBalance: number;
  createdAt: string;
  goalAmount?: number;
  goal: string;
  isTransferAndExempt: boolean;
  reinvestmentStrategy: string;
  isCustom: boolean;
  investmentOptions: string[];
  minimumDeposit: number;
  minimumRedemption: number;
  monthlyInvestment: number;
  riskCategory?: string;
  performanceFixedRate?: string;
  goalDuration?: string;
  volatility?: string;
  maximumDrawdown?: string;
}
export interface IProcessedUntilResponse {
  processedUntil: string;
}

export interface IPortfolioTransactionsSummary {
  portfolioId: string;
  transactionsNetBalance: number;
  totalIncoming: number;
  totalOutgoing: number;
  totalIncomeTax: IBFFNumberResponse;
  totalOperationTax: IBFFNumberResponse;
  totalNetOutgoing: IBFFNumberResponse;
  firstTransactionDate: string;
}

export interface IPortfolioTransactionsSummaryResponse {
  portfolioTransactionsSummary: IPortfolioTransactionsSummary;
}

export enum ECustomerUpToDateStatus {
  Overdue = 'overdue',
  UpToDate = 'upToDate'
}

export interface ICustomerUpToDateStatus {
  customerUpToDateStatus: {
    status: ECustomerUpToDateStatus;
  };
  portfolios: {
    data: IPortfolio[];
  };
}

export interface IPortfolioBalance {
  transactionsNetBalance: IBFFNumberResponse;
  totalIncoming: IBFFNumberResponse;
  totalOutgoing: IBFFNumberResponse;
  incomeTax: IBFFNumberResponse;
  totalNetWorth: IBFFNumberResponse;
  netBalance: IBFFNumberResponse;
  netBalanceOfFees: IBFFNumberResponse;
  pendingAdministrationTax: IBFFNumberResponse;
  pendingManagementTax: IBFFNumberResponse;
  totalGrossEarnings: IBFFNumberResponse;
  totalGrossWorth: IBFFNumberResponse;
  financialOperationTax: IBFFNumberResponse;
  administrationTax: IBFFNumberResponse;
}

export interface IPortfolioBalanceResponse {
  portfolioBalance: IBalance;
}
export interface IBalance {
  accountAvailableBalance: IBFFNumberResponse;
  administrationTax: IBFFNumberResponse;
  financialOperationTax: IBFFNumberResponse;
  incomeTax: IBFFNumberResponse;
  managementTax: IBFFNumberResponse;
  netBalance: IBFFNumberResponse;
  netBalanceOfFees: IBFFNumberResponse;
  pendingAdministrationTax: IBFFNumberResponse;
  pendingManagementTax: IBFFNumberResponse;
  totalGrossEarnings: IBFFNumberResponse;
  totalGrossWorth: IBFFNumberResponse;
  totalIncomeTax: IBFFNumberResponse;
  totalFinancialOperationTax: IBFFNumberResponse;
  totalNetEarnings: IBFFNumberResponse;
  pendingTotalTaxes: IBFFNumberResponse;
  profitValues: IProfitValue[];
  hasHistoryCut: boolean;
  historyCutDate?: {
    value: string;
    formatted: string;
  };
  totalBalanceUpdatedAtFormatted: string;
}

export interface IProfitValue {
  key: string;
  value: string;
}

export interface CustomerBalanceOptions {
  isReset: boolean;
}

export interface ICustomerBalanceResponse {
  customerBalance: IBalance;
}

export interface IDashStatusCardApi {
  message: string;
  title: string;
  image: IDashStatusCardImages;
  button: string;
  action: string;
}

interface IDashStatusCardImages {
  mobile: {
    full: string;
    regular: string;
    small: string;
  };
  web: {
    full: string;
    regular: string;
    small: string;
  };
}

export interface ISuggestedPortfolioTransaction {
  validatePortfolioTransaction: {
    isUnavailable: boolean;
    isOutsideOperationTime: boolean;
    isEditadaBox: boolean;
    message?: string;
    schedulingSuccessMessage?: string;
  };
}

export interface IWealthPerformanceResponse {
  performanceHistoryOverview: IDefaultProps[];
  updatedAt: {
    value: string;
    formatted: string;
  };
}

export interface IPerformanceDetailResponse {
  monthly: IMonthlyProps[];
  annual: IAnnualProps;
  title: number;
}

export interface IDefaultProps {
  label: string;
  value: string;
  valueDescription: string;
}

export interface IMonthlyProps {
  label: string;
  monthDetail: IDefaultProps[];
}

export interface IAnnualProps {
  name: string;
  annualDetail: IDefaultProps[];
}

export interface IProductAllocationsResponse {
  allocations: IProductAllocation[];
  lastUpdateAt: string;
  totalValue: IAllocValue;
}

export interface IProductAllocation {
  allocValue: IAllocValue;
  color: string;
  products: IProduct[];
  type: string;
  value: IAllocValue;
}

export interface IProduct {
  allocValue: IAllocValue;
  color: string;
  grossTotalBalance: IAllocValue;
  instrumentDetailApiId: string;
  name: string;
  rootClassName: string;
  totalBalance: IAllocValue;
}
