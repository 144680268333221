import { httpClient } from '@/modules/common/services/http/http-client';
import { WarrenSession } from '@/modules/common/services/session';
import {
  IGetAccountBalance,
  IGetPortfolioCatalogResponse,
  IPortfolioInvestmentSuccessRequest,
  IPortfolioInvestmentSuccessResponse,
  IInstrumentValidationResponse,
  getCustomerExperienceResponse,
  IUpdateCustomerExperiencePayload,
  IUpdateCustomerExperienceResponse,
  IGetInvestmentSuggestionResponse,
  IInvestmentAgreementPayload,
  IGetInstrumentProductValidationResponse,
  IPortfolioTransactionsSummaryResponse,
  ICustomerUpToDateStatus,
  IPortfolioBalanceResponse,
  CustomerBalanceOptions,
  ICustomerBalanceResponse,
  IProcessedUntilResponse,
  ISuggestedPortfolioTransaction,
  IWealthPerformanceResponse,
  IPerformanceDetailResponse,
  IProductAllocationsResponse
} from './investment-types';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getPortfolioCatalog(
  portfolioId: string,
  customerId: string
): Promise<IGetPortfolioCatalogResponse> {
  return http.get('/v1/getPortfolioCatalog', {
    params: {
      portfolioId,
      customerId
    }
  });
}

export function submitPortfolioInvestmentByProduct(
  payload: IPortfolioInvestmentSuccessRequest
): Promise<IPortfolioInvestmentSuccessResponse> {
  return http.post('v2/investmentByProduct', payload);
}

export function getAccountBalance(
  customer: string
): Promise<IGetAccountBalance> {
  return http.get(`/v1/getSummary?customer=${customer}`);
}

export function instrumentValidation(
  productsIds: string[]
): Promise<IInstrumentValidationResponse> {
  return http.get(`/v1/instrumentValidation`, {
    params: {
      instrumentIds: productsIds
    }
  });
}

export function portfolioInstrumentValidation(
  portfolioId: string
): Promise<IInstrumentValidationResponse> {
  return http.get(
    `/v1/portfolioInstrumentValidation?portfolioId=${portfolioId}`
  );
}

export function getCustomerExperience(
  customerId: string
): Promise<getCustomerExperienceResponse> {
  return http.get(`/v1/getCustomerExperience?customerId=${customerId}`);
}

export function getInvestmentSuggestions(
  customerId: string
): Promise<IGetInvestmentSuggestionResponse> {
  return http.get(`/v1/getInvestmentSuggestions`, {
    params: { customerId }
  });
}

export function getInstrumentProductValidation(
  productsIds: string[],
  customerId: string,
  portfolioId: string
): Promise<IGetInstrumentProductValidationResponse> {
  return http.get('/v1/investmentProductValidation', {
    params: {
      productsIds,
      customerId,
      portfolioId
    }
  });
}

export function updateCustomerExperience(
  payload: IUpdateCustomerExperiencePayload
): Promise<IUpdateCustomerExperienceResponse> {
  return http.put(`/v1/updateCustomerExperience`, payload);
}

export function investmentAgreement(payload: IInvestmentAgreementPayload) {
  return http.put('/v1/investmentAgreement', payload);
}

export function getPortfolioTransactionsSummary(
  portfolioId: string
): Promise<IPortfolioTransactionsSummaryResponse> {
  return http.get(
    `/v1/portfolioTransactionsSummary?portfolioId=${portfolioId}`
  );
}

export function getPortfolioProcessedUntil(
  portfolioId: string
): Promise<IProcessedUntilResponse> {
  return http.get(`/v1/getPortfolioProcessedUntil?portfolioId=${portfolioId}`);
}

export async function getPortfoliosToInvest(): Promise<ICustomerUpToDateStatus> {
  const customerId = WarrenSession.get('warren.customerId');
  return http.get(`/v1/customer/${customerId}/portfoliosToInvest`);
}

export function getPortfolioBalance(
  portfolioId: string
): Promise<IPortfolioBalanceResponse> {
  return http.get(`/v1/portfolioBalance`, {
    params: {
      portfolioId
    }
  });
}

export function getCustomerBalance({
  isReset
}: CustomerBalanceOptions): Promise<ICustomerBalanceResponse> {
  return http.get(`/v1/customer/balance`, {
    params: {
      reset: isReset
    }
  });
}

export function validatePortfolioTransaction(
  portfolioId: string,
  amount: number
): Promise<ISuggestedPortfolioTransaction> {
  return http.get(
    `/v1/validatePortfolioTransaction/?portfolioId=${portfolioId}&amount=${amount}`
  );
}

export function getPerformance(): Promise<IWealthPerformanceResponse> {
  return http.get('v1/getPerformance');
}

export function getWealthPerformanceDetails(
  year: string
): Promise<IPerformanceDetailResponse> {
  return http.get(`v2/getPerformanceHistoryDetail/${year}`);
}

export function getPortfolioPerformanceDetails(
  year: number,
  id: string
): Promise<IPerformanceDetailResponse> {
  return http.get(
    `v1/portfolio/getPerformanceHistoryDetail/${year}?portfolioId=${id}`
  );
}

export function getProductAllocations(): Promise<IProductAllocationsResponse> {
  return http.get('/v1/portfolios/productAllocations');
}
