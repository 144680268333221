import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

const riskColors: { [key: string]: NebraskaColors } = {
  LOW: NebraskaColors.statusPositive,
  MEDIUM: NebraskaColors.statusAlert,
  HIGH: NebraskaColors.statusNegative
};

export const columns = (products: number): DataTableProps.Column[] => {
  return [
    {
      label: 'Risco',
      width: '10%',
      getter: ({ risk }) => ({
        text: risk.label,
        color: riskColors[risk.value]
      })
    },
    {
      label: `${products} Produtos`,
      sort: 'Name',
      width: '25%',
      getter: ({ name, category }) => ({
        text: name,
        description: category
      })
    },
    {
      label: 'Mínimo inicial',
      align: DataTableProps.Align.Right,
      sort: 'MinimumInvestment',
      width: '17%',
      getter: 'minimumInvestment'
    },
    {
      label: 'Vencimento',
      align: DataTableProps.Align.Right,
      sort: 'MaturityDate',
      width: '17%',
      getter: 'maturityDate'
    },
    {
      label: 'Carência',
      align: DataTableProps.Align.Right,
      width: '16%',
      getter: 'liquidity'
    },
    {
      label: 'Taxa (a.a)',
      align: DataTableProps.Align.Right,
      width: '16%',
      getter: 'interestRate'
    }
  ];
};
