import { Route, RouteConfig } from 'vue-router';
import CostsControllerView from '@/modules/cash/views/CostsControllerView/CostsControllerView.vue';
import MonthlyCostsDetails from '@/modules/cash/views/MonthlyCostsDetails/MonthlyCostsDetails.vue';
import { Deposit } from '@/modules/cash/views/Deposit';
import { Cashback } from '@/modules/cash/views/Cashback';
import CostsDetails from '@/modules/cash/views/CostsDetails/CostsDetails.vue';
import CostsMoreDetails from '../views/CostsMoreDetails/CostsMoreDetails.vue';

export const cashRoutes: RouteConfig[] = [
  {
    name: 'costs',
    path: '/user/costs',
    component: CostsControllerView,
    meta: {
      context: 'cash'
    }
  },
  {
    name: 'costsMonthlyCostsDetails',
    path: '/user/costs/monthly-details/:year/:month',
    component: MonthlyCostsDetails,
    meta: {
      context: 'cash'
    }
  },
  {
    name: 'costsLearnMore',
    path: '/user/costs/learn-more',
    // this alias is only to serve the Android and iOS app
    alias: '/user/custos/saiba-mais',
    component: CostsMoreDetails,
    meta: {
      requiresAuth: false,
      context: 'cash'
    }
  },
  {
    path: '/deposit',
    redirect: { name: 'deposit-pix' }
  },
  {
    name: 'deposit-pix',
    path: '/deposit/pix',
    component: Deposit,
    meta: {
      context: 'cash',
      depositMethod: 'pix'
    }
  },
  {
    name: 'deposit-ted',
    path: '/deposit/ted',
    component: Deposit,
    meta: {
      context: 'cash',
      depositMethod: 'ted'
    }
  },
  {
    name: 'cashback',
    path: '/user/cashback',
    component: Cashback,
    meta: {
      context: 'cash'
    }
  },
  {
    name: 'costsDetails',
    path: '/user/costs/details/:year/:month',
    component: CostsDetails,
    meta: {
      context: 'cash'
    },
    props: ({ params }: Route) => ({
      year: params.year,
      month: params.month
    })
  }
];
