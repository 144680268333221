export function redirectToUrl(
  path = '',
  params?: { [key: string]: string },
  ignoreHost?: boolean
) {
  const url = getHrefFromPath(path, params || {}, ignoreHost);
  window.location.href = url;
}

export function getHrefFromPath(
  path = '',
  params?: { [key: string]: string },
  ignoreHost?: boolean
) {
  const url = ignoreHost
    ? `${path}`
    : `${window.location.protocol}//${window.location.host}/${path}`;

  return `${url}${params ? createParamsFromObject(params) : ''}`;
}

export function createParamsFromObject(params: { [key: string]: string }) {
  return Object.entries(params)
    .filter(([, paramValue]) => paramValue !== undefined)
    .reduce((paramsString, [paramKey, paramValue], index) => {
      return `${paramsString}${
        index > 0 ? '&' : '?'
      }${paramKey}=${encodeURIComponent(paramValue)}`;
    }, '');
}
