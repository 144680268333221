
import { Component, InjectReactive, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isImpersonate } from '@/modules/common/services/partner';
import { RegulatoryUpdateWarning } from '@/modules/common/components/RegulatoryUpdateWarning';
import { throttle } from 'lodash-es';
import { AccountStates } from '@/modules/common/services/http/account';

const UIModule = namespace('UIModule');
const CustomerModule = namespace('CustomerModule');

@Component({ components: { RegulatoryUpdateWarning } })
export default class HomeTemplate extends Vue {
  private removeScrollListener?: Function;

  @Ref()
  readonly contentSection!: HTMLElement;

  @InjectReactive('shouldTriggerHomeAnimations')
  public addLoadedClass!: boolean;

  @CustomerModule.Getter('getAccountStatus')
  readonly accountStatusResponse!: AccountStates;

  @UIModule.Mutation('setIsTransparentNavBar')
  private setIsTransparent!: (isTransparent: boolean) => void;

  @UIModule.Getter('getNavBarRef')
  private getNavBarRef!: Vue;

  get haveAccountStatus() {
    return !!this.accountStatusResponse.accountStatus;
  }

  get isImpersonate() {
    return isImpersonate();
  }

  mounted() {
    this.setIsTransparent(true);
    this.contentScrollPositionListener();
  }

  private contentScrollPositionListener() {
    const navbarRef = this.getNavBarRef;

    const onScroll = throttle(() => {
      const navbarHeight = navbarRef.$el.clientHeight;
      const offsetTop = this.contentSection.getBoundingClientRect().top;
      const isContentAtTop = offsetTop >= navbarHeight;
      const shouldChangeNavbarColor =
        navbarRef.$props.isTransparent !== isContentAtTop;

      if (shouldChangeNavbarColor) {
        this.setIsTransparent(isContentAtTop);
      }
    }, 100);

    if (navbarRef) {
      window.addEventListener('scroll', onScroll);
      this.removeScrollListener = () =>
        window.removeEventListener('scroll', onScroll);
    }
  }

  destroyed() {
    this.setIsTransparent(false);
    this.removeScrollListener?.();
  }
}
