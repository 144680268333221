export interface PortabilityRequestsData {
  head: string;
  title: string;
  description: string;
  buttonLabel: string;
}

export interface NewsResponse {
  getInvestmentSuggestions: {
    showSuggestions: boolean;
    showInvestmentSuggestions: boolean;
    showRedeemSuggestions: boolean;
  };
  portabilityRequests: PortabilityRequestsData | null;
}

export interface IExperienceResponse {
  data: IExperience[];
}

export interface IUpdateCustomerExperienceResponse {
  _id: string;
  idExperience: string;
  idCustomer: string;
  isUsed: boolean;
}

export interface IExperience {
  _id: string;
  idExperience: string;
  idCustomer: string;
  isUsed: boolean;
  experience: {
    _id: string;
    name: string;
    formId: string;
    title: string;
    subtitle: string;
    active: boolean;
    button: string;
    nameurl: string;
  };
}

export enum StatementDirection {
  DEBIT = 'Debit',
  CREDIT = 'Credit'
}

export interface HomeStatement {
  id: string;
  direction: StatementDirection;
  icon: string;
  name: string;
  description: string;
  date: string;
  value: {
    value: number;
    formatted: string;
  };
  clickable: boolean;
}

export interface HomeStatementsResponse {
  items: HomeStatement[];
}
export interface BalancesResponse {
  totalBalance: {
    title: string;
    value: string;
  };
  totalEarnings: {
    title: string;
    value: string;
  };
  headers: {
    images: IBalancesImages;
  };
  balancesLastUpdatedAtFormatted: string;
  earningsUpdatedAt: string;
}

export interface IBalancesImages {
  full: string;
  regular: string;
  small: string;
}

interface ValueFormatted<v = number, f = string> {
  value: v;
  formatted: f;
}

export interface CashSummaryResponse {
  cashSection: {
    status: CashSummaryStatus;
    summary: CashSummary;
  };
}

export interface CashSummaryStatus {
  hasInvestmentAccountEnabled: boolean;
  investmentAccountId: string;
  processingWarning?: string;
}

export interface CashSummary {
  netBalance: ValueFormatted;
  currentNetEarnings: ValueFormatted;
  accountAmount: ValueFormatted;
  transitAmount: ValueFormatted;
  projectedCreditAmount: ValueFormatted;
  projectedDebitAmount: ValueFormatted;
  totalProjectedAmount: ValueFormatted;
  reservedAmount: ValueFormatted;
  totalTransitAmount: TotalTransitAmount;
}

export interface TotalTransitAmount {
  amount: ValueFormatted;
  details: DetailsTotalTransitAmount;
}

export interface DetailsTotalTransitAmount {
  trade: TradeDetails;
  wealth: WealthDetails;
}

export interface TradeDetails {
  totalValue: ValueFormatted;
  items: TradeDetailsItems[];
}

export interface TradeDetailsItems {
  label: string;
  value: ValueFormatted;
}

export interface WealthDetails {
  projectedCreditAmount: ValueFormatted;
}

export enum ETypeNotification {
  'open-verify-email' = 'open-verify-email',
  'open-suggestions' = 'open-suggestions',
  'open-portability' = 'open-portability',
  'open-suitability' = 'open-suitability'
}

export interface INotification {
  title: string;
  description: string;
  action: {
    type: ETypeNotification;
  };
}

export interface INotifications {
  notifications: INotification[];
}
