import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IAddProductResponse,
  IGetLastState,
  IRemoveProductResponse,
  ICreateWallet
} from './products-acquisition-types';

export const httpBffInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function addProductToWallet(
  productId: string
): Promise<IAddProductResponse> {
  return httpBffInvestments.put(`/v2/products/${productId}/addToWallet`);
}

export function getLastState(portfolioId?: string): Promise<IGetLastState> {
  return httpBffInvestments.get(`/v3/products/getLastState`, {
    params: { portfolioId }
  });
}

export function removeProducts({
  productIds
}: {
  productIds: string[];
}): Promise<IRemoveProductResponse> {
  return httpBffInvestments.delete('/v2/products/removeToWallet', {
    data: {
      productIds
    }
  });
}

export function resetPortfolio(portfolioId = ''): Promise<void> {
  return httpBffInvestments.put(`/v1/resetPortfolio/${portfolioId}`);
}

export function createWallet(name: string): Promise<ICreateWallet> {
  return httpBffInvestments.post('/v2/home/portfolios/advanced', {
    name
  });
}
