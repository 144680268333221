import { redirectToUrl } from '../route/route';

export const isPartnerRunningStandalone = () => {
  return process.env.VUE_APP_PARTNER_STANDALONE === 'true';
};

export const redirectToPartnerArea = (userId: string) => {
  const PARTNER_DASH_URL = '/v/#/b2b/dash';
  const PARTNER_STANDALONE_DASH_URL = '/partner/#/b2b/dash';

  let url = PARTNER_DASH_URL;
  if (isPartnerRunningStandalone()) {
    url = PARTNER_STANDALONE_DASH_URL;
  }

  const urlParams: { [key: string]: string } = {
    category: 'account',
    action: 'Login',
    label: window.location.href,
    partnerId: userId
  };

  redirectToUrl(
    url,
    {
      trackLogin: 'true',
      userId,
      ...urlParams
    },
    true
  );
};
