var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.SignupTemplate,{attrs:{"show-back-button":true,"slide-animation":_setup.slideAnimationActive,"tablet-query":true,"gradient-background":_setup.gradientBackgroundActive,"primary":_setup.templateBackground,"over-primary":_setup.templateBackground,"data-testid":"signup-desktop"},on:{"on-back-click":_setup.onBackClick},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{class:{
          signup__infos: true,
          specialist: _setup.userHaveAdvisor
        }},[(_setup.partnerTheme)?_c('div',{staticClass:"signup__infos-partner"},[(_setup.partnerTheme.logo)?_c('img',{attrs:{"src":_setup.partnerTheme.logo,"alt":_setup.partnerTheme.brandName}}):_c(_setup.Heading,{attrs:{"level":1,"as":"h1"}},[_vm._v(" "+_vm._s(_setup.partnerTheme.brandName)+" ")])],1):(!_setup.partnerThemeLoading)?_c('div',{class:{
            'signup__infos-container': true,
            specialist: _setup.userHaveAdvisor
          }},[_c(_setup.BrandImage,{staticClass:"signup__infos-logo",attrs:{"image":_setup.EBrandImageLogos.WARREN_SECONDARY,"schema":_setup.EBrandImageSchemas.POSITIVE,"fixed-schema-color":true,"width":"120"}}),_c('div',{staticClass:"signup__infos-heading"},[_c(_setup.Heading,{attrs:{"level":1}},[_vm._v(" Investir toma tempo, mas não precisa ser o seu ")]),_c(_setup.BaseText,{attrs:{"size":"p4","color":_setup.NebraskaColors.elementAlpha}},[_vm._v(" Acesse a forma mais eficiente de investir. Para seu patrimônio e para sua vida. ")])],1),_c(_setup.FadeTransition,[(_setup.userHaveAdvisor)?_c('div',{staticClass:"signup__infos-specialist"},[_c('div',[_c(_setup.BaseText,{attrs:{"size":"p4","color":_setup.NebraskaColors.elementAlpha}},[_vm._v(" Seu especialista ")]),_c(_setup.BaseText,{attrs:{"size":"p3","color":_setup.NebraskaColors.staticWhite}},[_vm._v(" "+_vm._s(_setup.advisor.name)+" ")])],1),(_setup.advisor.image && _setup.imageVisible)?_c('img',{attrs:{"src":_setup.advisor.image,"alt":_setup.advisor.name},on:{"error":function($event){_setup.imageVisible = false}}}):_vm._e()]):_vm._e()])],1):_vm._e()])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"signup__form"},[_c('div',{staticClass:"signup__form-container"},[_c(_setup.FadeSlideTransition,[_c(_setup.componentStep,{tag:"component"})],1)],1)])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }