import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IStatementListResponse,
  IStatementListRequest,
  IBalancesResponse,
  IVoucherResponse,
  IVoucherRequest,
  IUpcomingTransfersResponse,
  IUpcomingTransfersRequest
} from './types';

const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/banking/v1/statement`
});

const consolidationHttp = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/consolidation`
});

export function getStatementList(
  filters: IStatementListRequest
): Promise<IStatementListResponse> {
  return http.get('/list', { params: filters });
}

export function getBalances(): Promise<IBalancesResponse> {
  return consolidationHttp.get('/v1/balance');
}

export function getVoucher(
  transactionId: string,
  filters?: IVoucherRequest,
  isUpcomingTransfer?: boolean
): Promise<IVoucherResponse> {
  return http.get(`/voucher/${transactionId}`, {
    params: { ...filters, isUpcomingTransfer }
  });
}

export function getUpcomingTransfers(
  filters: IUpcomingTransfersRequest
): Promise<IUpcomingTransfersResponse> {
  return http.get('/upcomingTransfers', { params: filters });
}
