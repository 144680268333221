import { httpClient } from '@/modules/common/services/http/http-client';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import {
  IUpdateEmailRequest,
  IUpdatePasswordRequest,
  AccountCheckIncomeTaxProviderResponse,
  AccessLinkIncomeTaxCalculatorProviderResponse
} from './types';
import { ICustomer } from '@/types/models';

const baseURL = `${process.env.VUE_APP_BFF_ACCOUNT_URL}`;
export const httpClientAccountBFF = httpClient.withOptions({ baseURL });

export function updateEmail(
  body: IUpdateEmailRequest
): Promise<IUpdateEmailRequest> {
  return httpClient.post('/api/v2/account/change-email', body);
}

export function updatePassword(
  body: IUpdatePasswordRequest
): Promise<IUpdatePasswordRequest> {
  return httpClient.put('/api/v2/account/password', body);
}

export function updateCustomer(data: any): Promise<ICustomer> {
  return httpClient.put('/api/v3/account/update', data, {
    headers: {
      'x-new-suitability': 'true'
    }
  });
}

export function checkIfHasAccountOnIncomeTaxCalculatorProvider(): Promise<AccountCheckIncomeTaxProviderResponse> {
  return httpClientAccountBFF.get(
    'v1/incomeTaxCalculator/checkIfHasAccountOnProvider',
    {
      params: {
        customerId: CustomerModule.getCustomerId
      }
    }
  );
}

export function generateAccessLinkForIncomeTaxCalculatorProvider(): Promise<AccessLinkIncomeTaxCalculatorProviderResponse> {
  return httpClientAccountBFF.post(
    'v1/incomeTaxCalculator/generateAccessLink',
    {
      customerId: CustomerModule.getCustomerId
    }
  );
}
