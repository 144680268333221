import { NavigationGuard } from 'vue-router';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { WarrenSession } from '@/modules/common/services/session';
import { StorageKeys } from '@/modules/common/services/local-storage';

export const logOffUser: NavigationGuard = async (_to, _from, next) => {
  CustomerModule.resetCustomer();
  CustomerModule.resetAccountStatus();
  const keysToPreserve = [
    StorageKeys.DarkMode,
    StorageKeys.LastPartnerDashRoute,
    StorageKeys.PartnerShowMoney,
    StorageKeys.PartnerDashNewsInformation,
    StorageKeys.PartnerProfileNewsInformation,
    StorageKeys.PartnerStatementNewsInformation,
    StorageKeys.PartnerPortfolioNewsInformation,
    StorageKeys.NewPartnerFeaturesViewed,
    StorageKeys.ShowedPortfoliosTemplateTutorial,
    StorageKeys.HasDownloadedFeeStatement,
    StorageKeys.HasShowedInvestmentViewTutorial,
    StorageKeys.HasUsedPositionDetailsButton
  ];

  if (_from.name === 'signup') {
    keysToPreserve.push(StorageKeys.Theme);
  }

  WarrenSession.clean(keysToPreserve);
  next();
};
