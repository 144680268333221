
import { Component } from 'vue-property-decorator';
import {
  Row,
  Heading,
  SkeletonLoader,
  NavBar,
  RoundedIcon,
  Grid,
  HelperView,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import {
  BffWithdraw,
  IWealthToWidthdrawData
} from '@/modules/wealth/services/withdraw';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import PortfolioCard from '../Wealth/components/PortfolioCard/PortfolioCard.vue';
import { trackWithdrawPortfolio } from './track-events';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    Heading,
    Row,
    RoundedIcon,
    SkeletonLoader,
    NavBar,
    PortfolioCard,
    Grid,
    HelperView,
    ButtonPrimary
  }
})
export default class WealthWithdrawSelection extends mixins(RouteMixin) {
  private readonly bff = new BffWithdraw();
  readonly NebraskaColors = NebraskaColors;

  private wealthList: IWealthToWidthdrawData[] = [];

  isLoading = false;
  hasError = false;

  created() {
    this.getPorfolioToWithdraw();
  }

  get wealthListMapper() {
    return this.wealthList.map(wealth => ({
      ...wealth,
      totalGrossWorth: wealth.totalBalance.value
    }));
  }

  private async getPorfolioToWithdraw() {
    this.isLoading = true;
    try {
      const response = await this.bff.getPortfoliosToWithdraw();
      this.wealthList = response.portfolios.data;
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  goToWithdrawFlow(wealth: IWealthToWidthdrawData) {
    return wealth.isCustom
      ? this.redirectToWithdrawByProduct(wealth)
      : this.redirectToWithdrawByPortfolio(wealth);
  }

  private redirectToWithdrawByProduct(wealth: IWealthToWidthdrawData) {
    this.trackWithdrawClick(wealth.name);
    return this.$router.push({
      name: 'wealthProductWithdrawal',
      params: {
        id: wealth.id
      }
    });
  }

  private trackWithdrawClick(wealthName: string) {
    trackWithdrawPortfolio(wealthName.trim());
  }

  private redirectToWithdrawByPortfolio(wealth: IWealthToWidthdrawData) {
    this.$router.push({
      name: 'wealthPortfolioWithdrawal',
      params: { id: wealth.id }
    });

    trackWithdrawPortfolio(wealth.name.trim());
  }

  handleBackClick() {
    return this.$router.push({
      name: 'wealth'
    });
  }
}
