import { trackAmplitudeEvent } from '@/modules/home/helpers/track-amplitude-event';

export function trackHomePageView() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackWorthErrorLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view_error_worth'
  });
}

export function trackReloadWorthErrorClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'hero',
    event_name: 'try_again_worth',
    event_target: 'Tentar novamente'
  });
}
