import { compareTwoStrings } from 'string-similarity';

export function compareStringsPercentage(str1: string, str2: string) {
  if (!str1 || !str2) return;
  return compareTwoStrings(str1, str2);
}

const REGEX_VALIDATE_EMAIL =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const knownServices: IKnownDomain[] = [
  { domain: 'icloud.com', accuracy: 0.6 },
  { domain: 'live.com', accuracy: 0.6 },
  { domain: 'gmail.com', accuracy: 0.6 },
  { domain: 'hotmail.com', accuracy: 0.3 },
  { domain: 'outlook.com', accuracy: 0.6 },
  { domain: 'outlook.com.br', accuracy: 0.8 },
  { domain: 'uol.com.br', accuracy: 0.8 },
  { domain: 'bol.com.br', accuracy: 0.8 }
];

export interface IKnownDomain {
  domain: string;
  accuracy: number;
}

function findMatch(domain: string): string {
  const lowerCaseDomain = domain.toLowerCase();
  const domainSimilarities = knownServices.map(service => ({
    similarity: compareStringsPercentage(service.domain, lowerCaseDomain),
    service
  }));

  const bestMatch = domainSimilarities.reduce(
    (
      best: { similarity: number | undefined; service: IKnownDomain | null },
      current
    ) => {
      if (
        current.similarity !== undefined &&
        best.similarity !== undefined &&
        current.similarity > best.similarity &&
        current.similarity >= current.service.accuracy
      ) {
        return current;
      }
      return best;
    },
    { similarity: 0, service: null }
  );

  return bestMatch.service ? bestMatch.service.domain : '';
}
function getSuggestion(account: string, domain: string): string {
  const domainSuggestion = findMatch(domain);
  return domainSuggestion ? `${account}@${domainSuggestion}` : '';
}

function isKnown(domain: string): boolean {
  const lowerCaseDomain = domain.toLowerCase();
  return knownServices.some(service => service.domain === lowerCaseDomain);
}

export function emailSuggester(email: string): IEmailSuggesterResult {
  const isValid = REGEX_VALIDATE_EMAIL.test(email);
  if (!isValid) return { email, isValid, suggestion: '', isKnownDomain: false };
  const emailParts = email.split('@');
  const isKnownDomain = isKnown(emailParts[1]);
  return {
    email,
    isValid,
    isKnownDomain,
    suggestion: isKnownDomain
      ? email
      : getSuggestion(emailParts[0], emailParts[1])
  };
}
export interface IEmailSuggesterResult {
  email: string;
  suggestion?: string;
  isValid: boolean;
  isKnownDomain?: boolean;
}
