import { render, staticRenderFns } from "./RiskValidation.vue?vue&type=template&id=3fc16330&scoped=true&"
import script from "./RiskValidation.vue?vue&type=script&lang=ts&"
export * from "./RiskValidation.vue?vue&type=script&lang=ts&"
import style0 from "./RiskValidation.vue?vue&type=style&index=0&id=3fc16330&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc16330",
  null
  
)

export default component.exports