export enum ActionTypes {
  StartRegister = 'start_register',
  StartSuitability = 'start_suitability',
  FirstDeposit = 'first_deposit',
  FirstTradeDeposit = 'first_trade_deposit',
  FixRegister = 'fix_register',
  VerifyEmail = 'verify_email',
  SetPassword = 'set_password',
  StartGenericDeposit = 'start_deposit',
  StartCashDeposit = 'start_cash_deposit',
  StartWealthDeposit = 'start_wealth_deposit',
  StartTradeDeposit = 'start_trade_deposit',
  StartTradeTransaction = 'start_trade_transaction',
  StartBuilder = 'start_builder',
  SelectStock = 'select_stock',
  UpdateToRegulation = 'update_to_regulation',
  EnableBroker = 'ENABLE_BROKER'
}

export enum DashStatusButtonActions {
  Search = 'SEARCH',
  Transaction = 'TRANSACTION',
  DepositConversation = 'DEPOSIT_CONVERSATION',
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
  BrokerageNotes = 'BROKERAGE_NOTES'
}
