import { Profession } from '@/modules/account/services/resources/types';

export enum InputStatusType {
  Default = 'default',
  Error = 'error',
  Success = 'success'
}

export enum CustomerType {
  PF = 'pf',
  PJ = 'pj'
}

export type RegulatoryUpdateSteps = {
  icon: string;
  label: string;
};

export enum RegulatoryUpdateNavigationAction {
  RegulatoryRegisterUpdate = 'go_to_regulatory_register_update',
  Suitability = 'go_to_suitability',
  Facematch = 'go_to_facematch',
  Home = 'go_to_home',
  Password = 'go_to_password',
  Success = 'go_to_success',
  Security = 'go_to_mfa',
  RegulatoryTerms = 'go_to_accept_terms',
  PersonalRegisterUpdate = 'go_to_personal_info_register_update'
}

export type RegulatoryUpdateNavigation = {
  actions: RegulatoryUpdateNavigationAction[];
  title: string;
};

export interface RegulatoryUpdateAnnouncementContentPF {
  title?: string;
  description?: string;
  steps?: RegulatoryUpdateSteps[];
  navigation?: RegulatoryUpdateNavigation;
}

export interface RegulatoryUpdateAnnouncementContentResponse {
  customerType: CustomerType;
  content?: RegulatoryUpdateAnnouncementContentPF;
}

export interface RegulatoryUpdatePersonalData {
  maritalStatus: string;
  partnerName?: string;
  partnerCpf?: string;
  partnerForeignRne?: string;
  profession: Profession;
  hasProfession: boolean;
  workOrganization?: string;
  workOrganizationCnpj?: string;
}

export interface InvestmentProfile {
  totalInvested: number;
  movableProperty: number;
  privatePension: number;
  realEstate: number;
}
