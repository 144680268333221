enum InstrumentClass {
  Fixedincome = 'FIXEDINCOME',
  Variableincome = 'VARIABLEINCOME',
  Others = 'OTHERS'
}

export interface IFormattedValue {
  value: InstrumentClass;
  formatted: string;
}

export enum AlertTypes {
  success = 'success',
  negative = 'negative',
  info = 'info',
  alert = 'alert'
}

export enum ContentHTMLTags {
  SPAN = 'span',
  LINK = 'link',
  BREAKROW = 'breakRow'
}

export interface IDynamicContent {
  type: ContentHTMLTags;
  text: string;
  link?: string;
  style?: string;
}
