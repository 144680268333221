import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { modules } from './routes/modules';
import { scrollBehavior } from './helpers/scroll-behavior';
import { authGuard } from './navigation-guards/authGuard';
import { handleNavigationError } from './helpers/handleNavigationError';
import isImpersonateGuard from './navigation-guards/isImpersonateGuard';
import { platformMaintenance } from '@/router/navigation-guards/platformMaintenance';
import NotFoundView from '@/modules/common/views/NotFound/NotFoundView.vue';
import setLogContext from './navigation-guards/setLogContext';
import { clientEnabled } from './navigation-guards/clientEnabled';
import { isMobileDevice } from './navigation-guards/isMobileDevice';

Vue.use(Router);

const notFoundRoute: RouteConfig = {
  name: '404',
  path: '*',
  component: NotFoundView
};
const routes: RouteConfig[] = [...modules, notFoundRoute];

const router = new Router({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, _, savedPosition) {
    return scrollBehavior(savedPosition, to);
  }
});

router.beforeEach((to, from, next) => {
  authGuard(to, from, next);
  isImpersonateGuard(to, from, next);
  isMobileDevice(to, from, next);
  clientEnabled(to, from, next);
  setLogContext(to, from, next);
});
router.beforeResolve((to, from, next) => {
  platformMaintenance(to, from, next);
});
router.onError(handleNavigationError(router));

export default router;
