
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  NavBar,
  Heading,
  BaseModal,
  Row,
  RoundedIcon,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { ModalFooterWallet } from '@/modules/wallet-builder/src/components/Modals/ModalFooter';
import DownloadDocument from '../../DownloadDocument/DownloadDocument.vue';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import TagRiskLevel from '../../TagRiskLevel/TagRiskLevel.vue';

import { BffInvestments } from '@/modules/wallet-builder/src/services/bff-investments';
import {
  IInstrumentDetails,
  IInstrumentDetailsFormatted
} from '../../../services/types/models/IBffInvestments';
import { getInstrumentDetails } from '@/modules/wallet-builder/src/services/bff-platorm';

@Component({
  components: {
    Row,
    LoaderIndicator,
    DownloadDocument,
    NavBar,
    BaseModal,
    ModalFooterWallet,
    Heading,
    TagRiskLevel,
    RoundedIcon
  }
})
export default class InstrumentDetails extends Vue {
  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  @Prop({ type: String, default: 'FECHAR' })
  public leftButtonLabel!: string;

  @Prop({ type: String, default: 'FECHAR' })
  public rightButtonLabel!: string;

  @Prop({ type: String })
  public instrumentDetailApiId?: string;

  @Prop({ type: Boolean, required: false, default: true })
  public addProduct?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  public isInstrumentAdded?: boolean;

  @Prop({ type: String, required: false, default: '' })
  public productToLoading!: string;

  @Prop({ type: Boolean, required: false, default: true })
  public showFooter!: boolean;

  private isLoading = false;

  public NebraskaColors = NebraskaColors;

  private instrument: IInstrumentDetails | null = null;
  private errors: Error | null = null;

  private showTypeInvestor = false;

  private investorTypeFormatted = {
    QUALIFIED: 'Investidor qualificado',
    PROFESSIONAL: 'Investidor profissional'
  };

  public bff = new BffInvestments();

  private get formattedInstrument(): IInstrumentDetailsFormatted {
    return {
      ...this.instrument,
      minimumFinancialMovement: this.instrument?.minimumFinancialMovement
        .value as number,
      minimumInitialInvestment: this.instrument?.minimumInitialInvestment
        .value as number
    };
  }

  private leftButtonClicked() {
    this.$emit('handle-instrument-add-clicked', this.formattedInstrument);
  }

  private rightButtonClicked() {
    this.$emit('handle-instrument-remove-clicked', this.formattedInstrument);
  }

  @Watch('instrumentDetailApiId')
  public async fetch() {
    this.isLoading = true;
    if (!this.instrumentDetailApiId) {
      return;
    }
    try {
      this.instrument = await getInstrumentDetails(this.instrumentDetailApiId);

      this.showTypeInvestor =
        this.instrument?.investorType === 'QUALIFIED' ||
        this.instrument?.investorType === 'PROFESSIONAL';

      this.trackLoadInstrumentDetails();
    } catch (e) {
      this.errors = e as Error;
    } finally {
      this.isLoading = false;
    }
  }

  trackDownloadButton(computedTitle: string) {
    this.$emit('track-download-button', computedTitle);
  }

  trackLoadInstrumentDetails() {
    if (this.isOpen && this.instrument) {
      this.$emit('track-load-instrument-details', this.instrument);
    }
  }

  private beforeMount() {
    if (this.instrumentDetailApiId) {
      this.fetch();
    }
  }
}
