import Vue from 'vue';
import Vuex from 'vuex';

// Dynamic Modules
import { IDashModule } from '@/modules/common/store/DashModule';
import { ILastRedeemByPortfolio } from '@/modules/wealth/store/redeemWithdrawPortfolio/redeem-types';
import {
  UIModuleType,
  UIStoreModule
} from '@/modules/common/store/UIModule/module';
import { TransactionState } from './modules/TransactionModule';
import { TradeState } from '@/modules/trade/store/trade';
import { VuexPensionLandingModule } from '@/modules/pension/store/pension-landing';
import { VuexPensionInstrumentModule } from '@/modules/pension/store/pension-instrument';
import { PortfolioStoreModule } from '@/store/modules/PortfolioModule/module';
import { InvestmentsStoreModule } from '@/modules/wealth/store/investments/module';

// Static Modules
import { CustomerStoreModule } from '@/modules/common/store/CustomerModule/module';
import { FeatureFlagsStoreModule } from '@/modules/common/store/FeatureFlagsModule/module';
import { NotificationsStoreModule } from './modules/NotificationsModule/module';
import { FeatureSurveyStoreModule } from './modules/FeatureSurveyModule/module';
import setUserDatadogRum from '@/plugins/datadog-rum';
import { RedeemStoreModule } from '@/modules/wealth/store/redeem/module';
import { FilterModule } from '@/modules/wallet-builder/src/store/filter/module';

Vue.use(Vuex);
const datadogSetUserPlugin = setUserDatadogRum();

export interface IRootState {
  dashModule: IDashModule;
  CustomerModule: CustomerStoreModule;
  transactionModule: TransactionState;
  tradeModule: TradeState;
  PensionLandingModule: VuexPensionLandingModule;
  PensionInstrumentModule: VuexPensionInstrumentModule;
  FeatureFlagsModule: FeatureFlagsStoreModule;
  NotificationsModule: NotificationsStoreModule;
  PortfolioModule: PortfolioStoreModule;
  UIModule: UIModuleType;
  FeatureSurveyModule: FeatureSurveyStoreModule;
  InvestmentsModule: InvestmentsStoreModule;
  RedeemModule: RedeemStoreModule;
  RedeemWithdrawPortfolioModule: ILastRedeemByPortfolio;
}

export default new Vuex.Store<IRootState>({
  modules: {
    CustomerModule: CustomerStoreModule,
    FeatureFlagsModule: FeatureFlagsStoreModule,
    NotificationsModule: NotificationsStoreModule,
    PortfolioModule: PortfolioStoreModule,
    UIModule: UIStoreModule,
    FeatureSurveyModule: FeatureSurveyStoreModule,
    InvestmentsModule: InvestmentsStoreModule,
    RedeemModule: RedeemStoreModule,
    FilterModule
  },
  plugins: [datadogSetUserPlugin]
});
