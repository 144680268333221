
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { BaseText, InputSelect } from '@warrenbrasil/nebraska-web';
import { QualifiedInvestor } from './components/QualifiedInvestor';

import { getString, getEnumOptions } from '@/modules/common/helpers/resources';

import {
  ProfessionInput,
  ProfessionList
} from '@/modules/common/components/ProfessionInput';
import { CheckboxRadio } from '../CheckboxRadio';
import { InputLabelField } from '@/modules/account/components/InputLabelField';

@Component({
  components: {
    BaseText,
    CheckboxRadio,
    InputLabelField,
    InputSelect,
    QualifiedInvestor,
    ProfessionInput
  }
})
export default class PersonalDataTab extends Vue {
  @Prop({ required: false, default: false })
  private hasRegulationUpdate!: boolean;

  @Prop({ required: true }) private user!: any;

  private getEnumOptions = getEnumOptions;
  private getString = getString;

  private readonly cnpjmask = '##.###.###/####-##';
  private readonly cpfmask = '###.###.###-##';
  private readonly rneMask = 'X######-X';

  private get enablePartnerCpf() {
    switch (this.user.personalProfile.maritalStatus) {
      case 'casado-brasileiro-nato':
      case 'casado-brasileiro-naturalizado':
      case 'uniao-estavel':
        return true;
      default:
        return false;
    }
  }

  private get enabledPartnerRne() {
    return this.user.personalProfile.maritalStatus === 'casado-estrangeiro';
  }

  private get isSingle() {
    return /desquitado|divorciado|solteiro|viuvo/.test(
      this.user.personalProfile.maritalStatus
    );
  }

  private created() {
    this.validateProfession();
  }

  @Emit('validate-field')
  private validateProfession() {
    return {
      field: 'profession',
      isValid: !!this.user?.personalProfile?.profession
    };
  }

  private setProfession(option: ProfessionList) {
    this.user.personalProfile.profession = option.profession;
    this.user.personalProfile.professionId = option.cbo;
    this.validateProfession();
  }

  public changeHasProfession() {
    if (!this.user.personalProfile.hasProfession) {
      this.user.personalProfile.workOrganization = '';
      this.user.personalProfile.workOrganizationCnpj = '';
    }
  }

  public changeMaritalStatus() {
    if (this.isSingle) {
      this.user.personalProfile.partnerCpf = '';
      this.user.personalProfile.partnerName = '';
      this.user.personalProfile.partnerForeignRne = '';
    }

    if (this.enablePartnerCpf) {
      this.user.personalProfile.partnerForeignRne = '';
    }

    if (this.enabledPartnerRne) {
      this.user.personalProfile.partnerCpf = '';
    }
  }

  public get professionInputErrorMessage() {
    if (!this.user.personalProfile.profession) {
      return 'Selecione uma profissão';
    }

    if (!this.user.personalProfile.professionId) {
      return 'Atualizamos o campo de ocupação. Por favor, selecione sua ocupação atual.';
    }

    return '';
  }
}
