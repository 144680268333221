import { object, string } from 'yup';

export enum EmailErrors {
  Required = 'Informe o e-mail',
  Invalid = 'Informe um e-mail válido'
}

export const advisorEmailSchema = object().shape({
  advisorEmail: string()
    .required(EmailErrors.Required)
    .email(EmailErrors.Invalid)
});
