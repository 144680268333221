<template>
  <transition name="fade-slide-transition" :mode="mode">
    <slot />
  </transition>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  mode: {
    type: String,
    default: 'out-in'
  }
});

const mode = ref(props.mode);
</script>

<style lang="less" scoped>
.fade-slide-transition {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s, transform 0.5s;
    transform: translateX(0);
  }

  &-enter {
    opacity: 0;
    transform: translateX(10%);
  }

  &-leave-to {
    opacity: 0;
    transform: translateX(-10%);
  }
}
</style>
