export enum ContentHTMLTags {
  SPAN = 'span',
  LINK = 'link'
}

export interface FeeTransparencyDisclaimer {
  title: string;
  content: {
    type: ContentHTMLTags;
    text: string;
    link?: string;
  }[];
}
