import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IConsolidatedExtractAvailableDates,
  IIncomeReportYears,
  IFixedIncomeNoteList
} from './types';

export const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export const httpReports = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/reports`
});

export function getConsolidatedStatement(
  month: number,
  year: number
): Promise<BlobPart> {
  return http.get(`v1/customers/reports/getConsolidatedExtractReport`, {
    params: { month, year },
    responseType: 'blob'
  });
}

export async function getConsolidatedExtractAvailableDates(): Promise<IConsolidatedExtractAvailableDates> {
  const response = await http.get(
    `v1/customers/reports/getConsolidatedExtractAvailableDates`
  );
  return response.availableDates;
}

export function getAllocationReport(
  month: number,
  year: number
): Promise<BlobPart> {
  return http.get(`v1/customers/reports/getAllocationReport`, {
    params: { month, year },
    responseType: 'blob'
  });
}

export function getIncomeReportYears(): Promise<IIncomeReportYears[]> {
  return http.get('v2/customers/reports/getIncomeReportYears');
}

export function getIncomeReport(year: number, type: string): Promise<BlobPart> {
  return httpReports.get(`/v1/income-report/${year}/${type}`, {
    responseType: 'blob'
  });
}

export function getFixedIncomeNoteList(): Promise<IFixedIncomeNoteList> {
  return http.get(
    `v1/customers/reports/getTradingNotesAvailableDates/fixedIncome`
  );
}

export function getFixedIncomeNoteReport(
  reportId: string,
  reportName: string
): Promise<BlobPart> {
  return http.get(
    `v1/customers/reports/getTradingNoteReport/fixedIncome/${reportId}`,
    {
      params: { reportName },
      responseType: 'blob'
    }
  );
}
