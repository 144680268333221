import { IConversationDataTransaction } from '@/types/models/Transaction';
import { IPortfolio } from '@/types/models';

export interface IConversationQuestion {
  id: string;
  clearMessages?: boolean;
  portfolio?: IPortfolio;
  customerInfo?: IConversationQuestionCustomerInfo;
  buttons: IConversationQuestionButton[];
  inputs: IConversationQuestionInput[];
  radios: IConversationQuestionRadio[];
  checkbox: IConversationQuestionCheckbox[];
  selects?: IConversationQuestionSelect[];
  messages: IConversationQuestionMessage[];
  responses: string[];
  transaction?: IConversationQuestionTransaction;
  rows?: IConversationQuestionRow[];
  result?: {
    validCustomer: boolean;
  };
}

export interface IConversationQuestionTransaction {
  amount: number;
  fromPortfolioName: string;
  toPortfolios: IConversationQuestionPortfolio;
  useCurrencyAccountBalance: boolean;
  directDebit: boolean;
}

interface IConversationQuestionPortfolio {
  amount: number;
  toPortfolio: string;
  portfolioName?: string;
  portfolio?: string;
  useCurrencyAccountBalance: boolean;
}

interface IConversationQuestionCustomerInfo {
  isAffiliate: boolean;
  brandDomain?: string;
}

export interface IConversationQuestionInput {
  type: string;
  mask: string;
  defaultValue?: string;
  placeholder?: string;
  minValue?: number;
  maxValue?: number;
  quickAnswers?: IConversationQuestionInputQuickAnswer[];
}

export interface IConversationQuestionInputQuickAnswer {
  value: string;
  label: string;
}

export interface IConversationQuestionRadio {
  label: {
    title: string;
  };
  value: string;
}

export interface IConversationQuestionCheckbox {
  label: {
    title: string;
  };
  value: string;
}

export interface IConversationQuestionSelectValue {
  label: {
    title: string;
  };
  value: string;
}

export interface IConversationQuestionSelect {
  type: string;
}

export interface IConversationQuestionRow {
  id: string;
  titleLeft: string;
  titleRight: string;
  subtitleLeft: string;
}

export interface IConversationQuestionButton {
  label: {
    title: string;
    subtitle?: string;
    image?: string;
  };
  value: string;
  isProcessing?: boolean;
  style?: string;
}

export interface IConversationParsedPortfolios
  extends IConversationQuestionButton {
  isUserWallet: boolean;
}

export interface IConversationSlotQuestion {
  id: string;
  hasDefaultAnswerButton: boolean;
}

export interface IConversationAnswerData {
  value: string | string[];
  messageId: string;
}

export interface IConversationQuestionMessage {
  type: string;
  value: string | string[] | IConversationQuestionMessageCard[];
  userResponse?: boolean;
}

export interface IConversationQuestionMessageCard {
  action: IConversationQuestionMessageCardAction;
  props: IConversationQuestionMessageCardProps;
  disclaimer?: string;
}

export interface IConversationQuestionMessageCardAction {
  label: string;
  value: string;
}

export interface IConversationQuestionMessageCardProps {
  backgroundColorHex: string;
  header: IConversationQuestionMessageCardPropContent;
  body: IConversationQuestionMessageCardPropContent;
  badge: IConversationQuestionMessageCardPropsBadge;
}

export interface IConversationQuestionMessageCardPropContent {
  title: IConversationQuestionMessageCardElement;
  subtitle: IConversationQuestionMessageCardElement;
  description: IConversationQuestionMessageCardElement;
  subtitleOptional?: boolean;
  descriptionOptional?: boolean;
}

export interface IConversationQuestionMessageCardPropsBadge {
  label: IConversationQuestionMessageCardElement;
  backgroundColorHex: string;
}

export interface IConversationQuestionMessageCardElement {
  text: string;
  colorHex: string;
}

export type conversationAnswerDataTypes = IConversationDataTransaction;

export interface IBrandConversationDataInformation {
  brandReferral: string;
  brandNameInitial: string;
  brandDomain?: string;
}

export enum Foo {
  Unknown = 'unknown',
  Active = 'active',
  Passive = 'passive'
}
