import { CustomOperation, GetCustomOperationsSummaryResponse } from './types';
import { httpClient } from '@/modules/common/services/http/http-client';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getCustomOperationsSummary(
  symbol?: string
): Promise<GetCustomOperationsSummaryResponse> {
  return httpClientWithDefaultOptions.get('/v1/custom-operations/summary', {
    params: { symbol }
  });
}

export function getCustomOperationDetails(
  id: string,
  abortSignal?: AbortSignal
): Promise<CustomOperation> {
  return httpClientWithDefaultOptions.get(`/v2/custom-operations/${id}`, {
    abortSignal
  });
}
