
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';

import {
  Modal,
  ButtonPrimary,
  ButtonSecondary,
  ButtonContainer
} from '@warrenbrasil/nebraska-web';
import { OrderLoading } from './components/OrderLoading';
import { OrderDetails } from './components/OrderDetails';
import { OrderError } from './components/OrderError';
import { OrderStatus } from './components/OrderStatus';
import DynamicContentModal from '@/modules/common/components/DynamicContentModal/DynamicContentModal.vue';
import { PortabilityPriceChange } from './components/PortabilityPriceChange';
import { ModalStepTransition } from '@/modules/common/components/ModalStepTransition';
import { NotificationTypes } from '@/modules/common/services/http/notification';

import { OrderModalSteps, OrderModalButtonSlots } from './enums';
import { NumberFormatted } from '@/modules/trade/types';
import {
  getOrderDetails,
  OrderDetailsResponse,
  changePortabilityAveragePrice,
  OrderContentFieldTypes
} from '@/modules/trade/services/orders';
import {
  addBodyScrollWithScrollTo,
  removeBodyScroll
} from '@/modules/common/helpers/browser';
import { currencyToNumber } from '@/modules/common/helpers/currency';
import * as trackOrderEvents from './trackers';
import DynamicContent from '@/modules/common/components/DynamicContent/DynamicContent.vue';
import { FieldDetails } from '@/modules/trade/components/OrderModal/types';

@Component({
  components: {
    ButtonContainer,
    Modal,
    OrderLoading,
    OrderDetails,
    OrderError,
    PortabilityPriceChange,
    OrderStatus,
    ModalStepTransition,
    DynamicContentModal
  }
})
export default class OrderModal extends Vue {
  @Prop({ type: String, required: false })
  readonly id?: string;

  @Prop({ type: Boolean, required: true })
  readonly isOpen!: boolean;

  @Prop({ type: Object, required: false })
  readonly order?: OrderDetailsResponse;

  @Watch('order', { immediate: true })
  async watchOrder(order: OrderDetailsResponse) {
    if (order) {
      this.currentStep = OrderModalSteps.Details;
      this.orderData = order;
    }
  }

  @Watch('isOpen', { immediate: true })
  async watchIsOpen(isOpen: boolean) {
    if (isOpen) {
      this.averagePrice = '0';
      removeBodyScroll();
      if (!this.order) await this.fetchOrderDetails();
      this.track0rderLoad();
    } else {
      if (this.abortSignal) {
        this.abortSignal.abort();
      }
      addBodyScrollWithScrollTo();
    }
  }

  @Emit('change-average-price')
  emitChangeAveragePrice(_id: string, _averagePrice: NumberFormatted) {}

  @Emit('close')
  emitClose() {}

  currentStep = OrderModalSteps.Loading;
  readonly OrderModalSteps = OrderModalSteps;
  abortSignal: AbortController | null = null;
  orderData: OrderDetailsResponse | null = null;
  averagePrice = '0';
  buttonProps = {
    [OrderModalButtonSlots.ButtonPrimary]: {
      disabled: false,
      loading: false
    },
    [OrderModalButtonSlots.ButtonSecondary]: {
      disabled: false,
      loading: false
    }
  };

  statusProps = {
    status: 'negative',
    title: 'Não foi possível alterar o preço por ativo',
    description:
      'Não é permitido alterar esse tipo de transação. Entre em contato com nosso atendimento para saber mais.'
  };

  fieldDetailsProps: FieldDetails = {
    content: [],
    title: ''
  };

  get stepsData() {
    return {
      [OrderModalSteps.Portability]: {
        component: PortabilityPriceChange,
        props: {
          key: 'portability-price-change',
          order: this.orderData,
          averagePrice: this.averagePrice
        },
        events: {
          'change-average-price': this.changeAveragePrice,
          'change-button-status': this.changeButtonStatus
        },
        buttons: [
          {
            label: 'Cancelar',
            component: ButtonSecondary,
            slot: OrderModalButtonSlots.ButtonPrimary,
            click: this.handleCancelAveragePrice
          },
          {
            label: 'Alterar',
            component: ButtonPrimary,
            slot: OrderModalButtonSlots.ButtonSecondary,
            click: this.changePortabilityAveragePrice
          }
        ]
      },
      [OrderModalSteps.Error]: {
        buttons: [],
        component: OrderError,
        props: {
          key: 'order-error'
        },
        events: {
          'on-retry': this.handleOnRetry
        }
      },
      [OrderModalSteps.Loading]: {
        buttons: [],
        component: OrderLoading,
        props: {
          key: 'order-loading'
        },
        events: {}
      },
      [OrderModalSteps.Details]: {
        buttons: [],
        component: OrderDetails,
        props: {
          key: 'order-details',
          order: this.orderData
        },
        events: {
          'on-button-click': () =>
            this.changeCurrentStep(OrderModalSteps.Portability),
          'on-field-click': (fieldData: FieldDetails) =>
            this.handleFieldClick(fieldData)
        }
      },
      [OrderModalSteps.Status]: {
        component: OrderStatus,
        buttons: [
          {
            label: 'Concluir',
            component: ButtonSecondary,
            slot: OrderModalButtonSlots.ButtonPrimary,
            click: this.handleConfirmStatus
          }
        ],
        props: {
          key: 'order-status',
          ...this.statusProps
        },
        events: {}
      },
      [OrderModalSteps.FieldDetails]: {
        component: DynamicContent,
        buttons: [
          {
            label: 'Entendi',
            component: ButtonPrimary,
            slot: OrderModalButtonSlots.ButtonPrimary,
            click: () => this.changeCurrentStep(OrderModalSteps.Details)
          },
          {
            label: 'Ver Custos',
            component: ButtonSecondary,
            slot: OrderModalButtonSlots.ButtonSecondary,
            click: () => this.$router.push({ name: 'costs' })
          }
        ],
        props: {
          key: 'order-field-details',
          ...this.fieldDetailsProps
        },
        events: {}
      }
    };
  }

  get currentStepData() {
    return this.stepsData[this.currentStep];
  }

  get buttons() {
    return this.stepsData[this.currentStep].buttons;
  }

  async fetchOrderDetails() {
    try {
      this.currentStep = OrderModalSteps.Loading;
      this.abortSignal = new AbortController();

      this.orderData = await getOrderDetails(this.id!, this.abortSignal.signal);

      this.currentStep = OrderModalSteps.Details;
    } catch {
      trackOrderEvents.trackOrderDetailErrorLoad();
      this.currentStep = OrderModalSteps.Error;
    }
  }

  track0rderLoad() {
    const orderType = this.order?.type?.formatted || '';
    const orderCaption =
      this.orderData?.content.find(e => e.caption)?.caption || '';
    const orderStatusField = this.orderData?.content[0].fields.find(
      field => field.type === OrderContentFieldTypes.chip
    );
    const orderStatus = orderStatusField?.label || '';

    trackOrderEvents.trackOrderDetailLoad(orderType, orderStatus, orderCaption);
  }

  handleConfirmStatus() {
    trackOrderEvents.trackOrderDetailStatusCompleteClick();
    this.emitClose();
  }

  handleCloseModal() {
    trackOrderEvents.trackOrderDetailClose();
    this.emitClose();
  }

  handleCancelAveragePrice() {
    trackOrderEvents.trackOrderDetailCancelAveragePrice();
    this.emitClose();
  }

  async handleOnRetry() {
    trackOrderEvents.trackOrderDetailErrorRetryClick();
    await this.fetchOrderDetails();
  }

  async changePortabilityAveragePrice() {
    try {
      trackOrderEvents.trackOrderDetailChangeAveragePrice();
      this.buttonProps[OrderModalButtonSlots.ButtonSecondary].loading = true;
      this.abortSignal = new AbortController();

      const averagePriceNumber = currencyToNumber(this.averagePrice);

      const data = await changePortabilityAveragePrice(
        this.id!,
        averagePriceNumber,
        this.abortSignal.signal
      );

      this.statusProps = {
        status: data.status,
        title: data.title,
        description: data.message
      };

      if (data.status === 'positive') {
        this.emitChangeAveragePrice(this.id!, {
          formatted: `R$ ${this.averagePrice}`,
          value: averagePriceNumber
        });
      }

      this.changeCurrentStep(OrderModalSteps.Status);
    } catch {
      this.$notify({
        group: 'wrn-notifications',
        text: 'Houve algum problema ao alterar o preço médio da portabilidade, tente novamente mais tarde',
        type: NotificationTypes.error
      });
    } finally {
      this.buttonProps[OrderModalButtonSlots.ButtonSecondary].loading = false;
    }
  }

  handleFieldClick(prop: FieldDetails) {
    this.fieldDetailsProps.content = prop.content;
    this.fieldDetailsProps.title = prop.title;
    this.changeCurrentStep(OrderModalSteps.FieldDetails);
    this.changeButtonStatus(OrderModalButtonSlots.ButtonSecondary, false);
  }

  changeCurrentStep(step: OrderModalSteps) {
    this.currentStep = step;
  }

  changeButtonStatus(slot: OrderModalButtonSlots, isDisabled: boolean) {
    this.buttonProps[slot].disabled = isDisabled;
  }

  changeAveragePrice(averagePrice: string) {
    this.averagePrice = averagePrice;
  }
}
