import { isValid as isValidCPF } from 'cpf';
import { isValidPhone } from '@/modules/common/helpers/phone';
import { object, string } from 'yup';

export enum EmailErrors {
  Required = 'Informe o e-mail',
  Invalid = 'Informe um e-mail válido'
}

export enum NameErrors {
  Required = 'Informe como quer ser chamado',
  Wrong = 'Você deve preencher com o nome completo'
}

export enum CpfErrors {
  Required = 'CPF é obrigatório',
  Invalid = 'Informe um CPF válido'
}

export enum MobileErrors {
  Required = 'Celular é obrigatório',
  Invalid = 'Informe um celular válido'
}

export const initialFormStepSchema = object().shape({
  name: string()
    .required(NameErrors.Required)
    .test({
      test: (value: string) => {
        const names = value.split(' ');
        return names.length > 1;
      },
      message: NameErrors.Wrong
    }),
  email: string().required(EmailErrors.Required).email(EmailErrors.Invalid),
  cpf: string()
    .test({ test: isValidCPF, message: CpfErrors.Invalid })
    .required(CpfErrors.Required),
  phone: string()
    .transform((_, originalValue: string) => originalValue.replace(/\D/g, ''))
    .test({ test: isValidPhone, message: MobileErrors.Invalid })
    .required(MobileErrors.Required)
});
