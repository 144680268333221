
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  HeadingDisplay,
  ButtonPrimary,
  ListDisplay,
  IconButton,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { OrderType, OrderTypesPrimary, OrderTypesSecondary } from '../Order';
import { InstrumentStatus } from '@/modules/trade/services/instruments';
import { PropType } from 'vue';
import { IDynamicContent } from '@/modules/common/types';
import DynamicContentModal from '@/modules/common/components/DynamicContentModal/DynamicContentModal.vue';

@Component({
  filters: { money },
  components: {
    HeadingDisplay,
    ButtonPrimary,
    ListDisplay,
    IconButton,
    BaseText,
    DynamicContentModal
  }
})
export default class ConfirmOrder extends Vue {
  @Prop({ type: Number, required: true })
  readonly type!: number;

  @Prop({ type: Number, required: true })
  readonly instrumentQtd!: number;

  @Prop({ type: String, required: true })
  readonly balance!: string;

  @Prop({ type: Number })
  readonly bestOffer!: number;

  @Prop({ type: Boolean })
  readonly loadingButton!: boolean;

  @Prop({ type: String, required: true })
  readonly instrumentTitle!: string;

  @Prop({ type: String })
  readonly instrumentDescription!: string;

  @Prop({ type: Object, required: true })
  readonly instrument!: InstrumentStatus;

  @Prop({ type: Number, required: true })
  readonly positionQuantity!: number;

  @Prop({ type: String, default: '' })
  readonly description!: string;

  @Prop({ type: String, required: false })
  readonly brokerageFee!: string;

  @Prop({ type: Array as PropType<IDynamicContent[]>, required: false })
  readonly brokerageDisclaimer!: IDynamicContent[];

  readonly NebraskaColors = NebraskaColors;
  readonly ConfirmOrderType = OrderType;
  private isDynamicContentModalOpen = false;

  public toggleDynamicContentModal() {
    this.isDynamicContentModalOpen = !this.isDynamicContentModalOpen;
  }

  @Emit('request-order')
  public createOrder() {}

  get estimatedTotal() {
    return this.instrumentQtd * this.bestOffer;
  }

  get instrumentTitleAvatarName() {
    return this.instrumentTitle.replace(/\d[F]/g, '');
  }

  public displayValue(value: number) {
    return money(value);
  }

  get getTypeHeading() {
    return this.type === OrderType.Buy
      ? OrderTypesPrimary.Buy
      : OrderTypesPrimary.Sell;
  }

  get getTypeButton() {
    return this.type === OrderType.Buy
      ? OrderTypesSecondary.Buy
      : OrderTypesSecondary.Sell;
  }

  get computedButtonLabel() {
    return `${this.getTypeButton} ${this.instrument?.symbol}`;
  }
}
