import { render, staticRenderFns } from "./WealthProductTransaction.vue?vue&type=template&id=97e554ee&scoped=true&"
import script from "./WealthProductTransaction.vue?vue&type=script&lang=ts&"
export * from "./WealthProductTransaction.vue?vue&type=script&lang=ts&"
import style0 from "./WealthProductTransaction.vue?vue&type=style&index=0&id=97e554ee&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e554ee",
  null
  
)

export default component.exports