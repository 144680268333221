import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

const riskColors: { [key: string]: NebraskaColors } = {
  LOW: NebraskaColors.statusPositive,
  MEDIUM: NebraskaColors.statusAlert,
  HIGH: NebraskaColors.statusNegative
};

export const columns = (products: number): DataTableProps.Column[] => {
  return [
    {
      label: 'Risco',
      sort: 'riskScore',
      width: '8%',
      getter: ({ risk }) => ({
        text: risk.label,
        color: riskColors[risk.value]
      })
    },
    {
      label: `${products} Produtos`,
      sort: 'fantasyName',
      width: '24%',
      getter: ({ name, category }) => ({
        text: name,
        description: category
      })
    },
    {
      label: 'Mínimo inicial',
      align: DataTableProps.Align.Right,
      sort: 'minimumInitialInvestment',
      width: '14%',
      getter: 'minimumInvestment'
    },
    {
      label: 'Cotização do resgate',
      align: DataTableProps.Align.Right,
      sort: 'daysToQuotizationAtWithdraw',
      width: '20%',
      getter: ({ quotizationAtWithdraw, quotizationAtWithdrawType }) => ({
        text: quotizationAtWithdraw,
        description: quotizationAtWithdrawType
      })
    },
    {
      label: 'Taxa de adm. (a.a)',
      align: DataTableProps.Align.Right,
      sort: 'administrationTax',
      width: '17%',
      getter: 'administrationTax'
    },
    {
      label: 'Rentab. (12M)',
      align: DataTableProps.Align.Right,
      sort: 'performances.performanceLastTwelveMonths',
      width: '15%',
      getter: 'return'
    }
  ];
};
