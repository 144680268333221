
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Modal } from '@/modules/common/components/__deprecated__/Modal';
import { SimpleButton } from '@/modules/common/components/__deprecated__/SimpleButton';
import { AccountButton } from './components/AccountButton';
import { IAdvisorProfile } from '@/types/models';
import {
  getCustomTheme,
  IUserB2B,
  StorageKeys
} from '@/modules/common/services/local-storage';
import { B2bPartnerType } from '@/types/models/Customer/AdvisorProfile';
import { isPartnerRunningStandalone } from '@/modules/common/helpers/partner';

enum AccountType {
  Investor,
  Partner
}
interface IAccountButtonInfo {
  userName: string;
  accountDescription: string;
}
interface IInvestorAccountButtonInfo extends IAccountButtonInfo {
  accountDescription: 'Investidor';
  iconLetter: string;
}
interface IAdvisorAccountButtonInfo extends IAccountButtonInfo {
  avatarImageURL?: string;
  iconName?: string;
}

@Component({
  components: {
    Modal,
    AccountButton,
    SimpleButton
  },
  extends: Modal
})
export default class SwitchAccountModal extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public isOpen!: boolean;

  @Prop({ type: String, required: false, default: 'Alternar contas' })
  public title!: string;

  @Prop({ type: Object, required: true })
  public user!: IUserB2B;

  @Prop({ type: Boolean, required: false })
  public isNewInvestorAccount!: boolean;

  public AccountType = AccountType;
  public selectedAccountType: AccountType = AccountType.Partner;
  private localOpen = false;

  public open() {
    if (this.isOnInvestorAccount()) {
      this.selectedAccountType = AccountType.Investor;
    } else {
      this.selectedAccountType = AccountType.Partner;
    }
    this.localOpen = true;
  }

  public close() {
    this.localOpen = false;
  }

  get advisorAccountButtonInfo(): IAdvisorAccountButtonInfo {
    const userTheme = getCustomTheme();
    const info: IAdvisorAccountButtonInfo = {
      userName: this.user.brand.name,
      accountDescription: `${this.advisorProfileTypeToString(
        (this.user.advisorProfile as IAdvisorProfile).type!
      )} - ${this.user.name}`
    };
    if (userTheme && userTheme.avatar) {
      info.avatarImageURL = userTheme.avatar;
    } else {
      info.iconName = 'ic_person';
    }

    return info;
  }

  get investorAccountButtonInfo(): IInvestorAccountButtonInfo {
    return {
      userName: this.user.name!,
      accountDescription: 'Investidor',
      iconLetter: this.user.name![0]
    };
  }

  private isOnInvestorAccount() {
    return (
      window.location.href.includes('/app/#') ||
      window.location.href.includes('/app#/')
    );
  }

  private advisorProfileTypeToString(type: B2bPartnerType) {
    switch (type) {
      case B2bPartnerType.CFP:
        return 'Consultor';
      case B2bPartnerType.MANAGER:
        return 'Gestor';
      case B2bPartnerType.PLANNER:
        return 'Planejador';
      case B2bPartnerType.AAI:
        return 'Agente autônomo de investimentos';
      default:
        return '';
    }
  }

  private switchProfile() {
    const partnerDashPath = isPartnerRunningStandalone()
      ? '/partner/#/b2b/dash'
      : '/v/#/b2b/dash';
    switch (this.selectedAccountType) {
      case AccountType.Investor:
        // Quando o parceiro alterna para a conta de investidor precisamos assegurar que seu ID de customer
        // esteja setado no local-storage, precisamos fazer isso para sobrescrever o customerId que pode ter sido setado
        // caso o parceiro tenha impersonado algum cliente
        localStorage.setItem(StorageKeys.CustomerId, this.user._id);
        window.location.href = `${window.location.protocol}//${window.location.host}/app/#/dash`;
        this.close();
        break;
      case AccountType.Partner:
        localStorage.setItem(StorageKeys.IsPartnerAccountSession, 'true');
        window.location.href = `${window.location.protocol}//${window.location.host}${partnerDashPath}`;
        this.close();
        break;
    }
  }
}
