export interface SignInForm {
  email: string;
  password: string;
  token: string;
}

export enum SignInFormSteps {
  Email = 'email',
  Password = 'password'
}

export enum ForgotPasswordModalSteps {
  Input = 'input',
  Success = 'success'
}

export interface YupError {
  key: string;
  value: string;
}

export interface ConfirmResponse {
  acceptedTerms: boolean;
}
