const observerLastRow = (observerContentRow: IntersectionObserver) => {
  const rows = document.querySelectorAll('[data-testid="content-row"]');

  if (rows.length === 0) return;

  const lastRow = rows[rows.length - 1];
  observerContentRow.observe(lastRow);
};

export function registerObserver(getProductsCallback: Function) {
  const observerContentRow = new IntersectionObserver(
    async ([lastRow], observer) => {
      if (!lastRow.isIntersecting) return;

      observer.unobserve(lastRow.target);
      await getProductsCallback(false, true);
      observerLastRow(observerContentRow);
    }
  );

  observerLastRow(observerContentRow);
}
