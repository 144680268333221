import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { NavigationGuard } from 'vue-router';

export const isMobileDevice: NavigationGuard = (to, _from, next) => {
  const { ENABLE_BLOCK_PLATAFORM_ON_MOBILE_DEVICES } = FeatureFlags;

  const isFeatureFlagEnabled = FeatureFlagsModule.isEnabled(
    ENABLE_BLOCK_PLATAFORM_ON_MOBILE_DEVICES
  );

  const shouldRedirect =
    isFeatureFlagEnabled &&
    window.innerWidth < 767 &&
    to.name !== 'redirect-to-mobile' &&
    to.name !== 'landing-app';

  if (shouldRedirect) {
    to.name === 'signup'
      ? next({ name: 'landing-app', query: { from: to.name } })
      : next({ name: 'landing-app' });
  } else {
    next();
  }
};
