export interface IFormattedFilters {
  label: string;
  value: string;
  parameter: string;
  options: {
    label: string;
    value: string;
  }[];
}

export interface IOrdersPagination {
  page: number;
  pageSize: number;
  totalPages: number;
}

export enum TagStatus {
  CANCELED = 'Cancelado',
  CONCLUDED = 'Concluído',
  REQUESTING = 'Solicitando',
  QUOTING = 'Cotizando',
  QUOTED = 'Cotizado',
  PROCESSING = 'Processando',
  SCHEDULED = 'Agendado'
}

export enum OrderModalSteps {
  OrderDetails = 'orderdetails',
  ProductDetails = 'productdetails'
}
