
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { RouteMixin } from '@/modules/common/mixins/route';
import { OrderInput } from './components/OrderInput';
import {
  openRiskTermsModal,
  RiskTermsConfirmation,
  RiskTermsAnswers
} from '@/modules/trade/components/InstrumentRiskModal';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { percentageWithSign as percentage } from '@/modules/common/helpers/percentage';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseIcon,
  Row,
  BaseText,
  ListDisplay,
  InputNumber,
  ButtonPrimary,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import { isImpersonate } from '@/modules/common/services/partner';
import { AvailableBalance } from './components/AvailableBalance';
import { OrderTypesSecondary, OrderType, OrderErrors } from './types';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { ETradeTypeInstrument } from '@/modules/trade/services/instruments/types';
import { IInstrumentStatus } from '@/modules/trade/services/signalr';
import {
  trackOpenRiskModal,
  trackOrderOperationClick
} from '@/modules/trade/views/Instrument/trackers';
import { AvailableBalanceData } from '@/modules/trade/services/unified-account/types';
import { getUserAvailableBalance } from '@/modules/trade/services/unified-account';
import { WarrenModules } from '@/types/navigation';
import { MFAModules } from '@/modules/authentication/views/SecurityView/components/MultiFactorAuthentication';
import { AvailableBalance as AvailableBalanceV2 } from '@/modules/trade/components/AvailableBalance';
import { AccountStatus } from '@/modules/trade/services/account';
import { InstrumentStatus } from '@/modules/trade/services/instruments';

enum BalanceDataStatus {
  Loading = 'Loading',
  Error = 'Error',
  Defaut = 'Defaut'
}

@Component({
  filters: { money, percentage },
  components: {
    OrderInput,
    Row,
    BaseIcon,
    AvailableBalance,
    SkeletonLoader,
    BaseText,
    InputNumber,
    ButtonPrimary,
    ListDisplay,
    AvailableBalanceV2
  }
})
export default class Order extends mixins(RouteMixin) {
  balanceDataStatus = BalanceDataStatus.Defaut;
  availableBalanceData: AvailableBalanceData | null = null;

  @Prop({ type: Number, required: true })
  readonly priceMarket!: number;

  @Prop({ type: String, required: true })
  readonly instrumentTitle!: string;

  @Prop({ type: Number, required: true, default: OrderType.Buy })
  readonly type!: OrderType;

  @Prop({ type: Number, default: 0 })
  readonly quantityInstrument!: number;

  @Prop({ type: Boolean, required: false })
  readonly canOperate!: boolean;

  @Prop({ type: Object })
  readonly instrumentStatus!: IInstrumentStatus;

  @Prop({ type: Object, required: true })
  readonly status!: AccountStatus;

  @Prop({ type: Object, required: true })
  readonly instrument!: InstrumentStatus;

  @Prop({ type: Boolean, default: false })
  readonly isCustomerBlocked!: boolean;

  @Prop({ type: String, default: '' })
  readonly description!: string;

  @Prop({ type: Boolean, default: false })
  readonly loadingButton!: boolean;

  orderQuantity = 0;
  private actionLabel = this.instrumentTitle;
  readonly NebraskaColors = NebraskaColors;
  private alert: string | null = null;

  error: string | null = null;

  @Emit('on-accept-risk-terms')
  emitAcceptRiskTerms() {}

  @Emit('on-order-button-disabled')
  emitOnOrderButtonDisabled(_reason: string) {}

  get BalanceDataStatus() {
    return BalanceDataStatus;
  }

  get orderStatusType() {
    return this.error ? 'error' : 'default';
  }

  get balanceAvailableUnifiedAccountFormatted() {
    return this.availableBalanceData?.availableToOperateValue?.formatted;
  }

  get currentBalanceAvailable() {
    return this.availableBalanceData?.availableToOperateValue;
  }

  private get isImpersonateFlag() {
    return isImpersonate();
  }

  get lotSize() {
    return this.instrument.lotSize;
  }

  get isLot() {
    return this.instrument.lotSize === 100;
  }

  async created() {
    this.orderQuantity = this.lotSize;
    this.fetchAvailableBalance();
  }

  private get actionOrder() {
    return this.type === OrderType.Buy
      ? OrderTypesSecondary.Buy
      : OrderTypesSecondary.Sell;
  }

  get showAlertLink() {
    return this.alert !== OrderErrors.LotMaxMessage;
  }

  get toInstrumentView() {
    return { name: 'tradeInstrument', params: { ticker: this.actionLabel } };
  }

  async fetchAvailableBalance() {
    this.balanceDataStatus = BalanceDataStatus.Loading;
    try {
      this.availableBalanceData = await getUserAvailableBalance();
      this.balanceDataStatus = BalanceDataStatus.Defaut;
      if (this.type === OrderType.Buy) this.validateBuy();
      this.onLoadAvailableBalanceUnifiedAccount();
    } catch {
      this.balanceDataStatus = BalanceDataStatus.Error;
    }
  }

  @Emit('load-available-balance-unified-account')
  onLoadAvailableBalanceUnifiedAccount() {
    return this.availableBalanceData;
  }

  private typeOfOrderDisabled() {
    return this.type === OrderType.Buy
      ? this.isBuyDisabled()
      : this.isSellDisabled();
  }

  private isSellDisabled() {
    const emptyOrder = this.orderQuantity <= 0;
    if (emptyOrder || this.error) {
      return true;
    }
    return false;
  }

  private isBuyDisabled() {
    const emptyOrder = this.orderQuantity <= 0;
    const emptyBalanceAvailable =
      this.currentBalanceAvailable && this.currentBalanceAvailable.value === 0;
    if (emptyBalanceAvailable || emptyOrder || this.error) {
      return true;
    }
    return false;
  }

  getReasonToDisableButton() {
    if (!this.status.tradingAvailable) {
      return 'tradingAvailable';
    } else if (this.isImpersonateFlag) {
      return 'isImpersonateFlag';
    } else if (!this.status.marketAvailable?.isMarketAvailable) {
      return 'isMarketAvailable';
    } else if (this.alert) {
      return 'alert';
    } else if (this.error) {
      return 'error';
    } else if (this.isSubscriptionInstrument) {
      return 'isSubscriptionInstrument';
    } else if (this.instrumentInAuction) {
      return `instrumentInAuction (value: ${this.instrumentStatus?.value}, message: ${this.instrumentStatus?.message})`;
    } else if (this.typeOfOrderDisabled()) {
      return 'typeOfOrderDisabled';
    }

    return null;
  }

  private get isButtonDisabled() {
    const reasonToDisabledButton = this.getReasonToDisableButton();

    if (reasonToDisabledButton) {
      this.emitOnOrderButtonDisabled(reasonToDisabledButton);
      return true;
    }
    return false;
  }

  private async confirmTypeOrder() {
    if (this.isCustomerBlocked) {
      this.$router.push({
        name: 'regulatory-update',
        query: { cameFrom: WarrenModules.Trade }
      });
      return;
    }

    const orderValue = this.orderQuantity * this.priceMarket;
    trackOrderOperationClick(
      !this.isLot,
      this.type,
      orderValue,
      this.instrument.symbol,
      this.description,
      this.instrument.type!.formatted!
    );

    if (this.type === OrderType.Buy) {
      await this.buyConfirm();
    } else {
      this.sellConfirm();
    }
  }

  private get shouldEnableMFA() {
    return this.hasEnableMFAFlag && !this.status.enabledMFA;
  }

  private get hasEnableMFAFlag() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.MFA_ENABLE_PROCESS);
  }

  get negotiationAvailable() {
    return this.status.marketAvailable!.negotiationAvailable!;
  }

  private get orderMessage() {
    return this.status?.marketAvailable?.innerDescription;
  }

  private goToMFAPage() {
    this.$router.push({
      name: 'security',
      query: {
        module: MFAModules.TRADE,
        ticker: this.instrumentTitle
      }
    });
  }

  public openSuitabilityModal() {
    this.$router.push({
      query: {
        ...this.$route.query,
        suitabilityModal: 'true'
      }
    });
  }

  private async requestAcceptRiskTerms() {
    trackOpenRiskModal(this.instrument.symbol);

    const { answer } = (await openRiskTermsModal({
      symbol: this.instrument.symbol,
      instrumentType: this.instrument.type!.value!,
      riskProfile: this.instrument.riskProfile!,
      orderType: this.type === OrderType.Buy ? 'buy' : 'sell'
    })) as RiskTermsConfirmation;

    switch (answer) {
      case RiskTermsAnswers.REDO_SUITABILITY:
        this.openSuitabilityModal();
        return false;
      case RiskTermsAnswers.ACCEPT_RISK_TERMS:
        this.emitAcceptRiskTerms();
        return true;
      case RiskTermsAnswers.CLOSE_RISK_TERMS:
        return false;
    }
  }

  private validateBuy() {
    if (this.balanceDataStatus === BalanceDataStatus.Defaut) {
      if (
        typeof this.currentBalanceAvailable?.value !== 'undefined' &&
        this.priceTotal > this.currentBalanceAvailable.value
      ) {
        this.error = OrderErrors.BuyMessage;
        return false;
      }
    }

    return true;
  }

  private async buyConfirm() {
    const isEnableToBuy = this.validateBuy();

    if (!this.canOperate) {
      const hasAcceptedRiskTerms = await this.requestAcceptRiskTerms();
      if (!hasAcceptedRiskTerms) return;
    }

    if (isEnableToBuy) {
      this.$emit('confirm-order', this.orderQuantity, this.priceMarket);
    }
  }

  private validateSell() {
    if (this.orderQuantity > this.quantityInstrument) {
      this.error = OrderErrors.SellMessage;
      return false;
    }
    return true;
  }

  private async sellConfirm() {
    if (!this.canOperate) {
      const hasAcceptedRiskTerms = await this.requestAcceptRiskTerms();
      if (!hasAcceptedRiskTerms) return;
    }

    this.$emit('confirm-order', this.orderQuantity, this.priceMarket);
  }

  private validateStock() {
    const isStockType =
      this.instrument.type?.value === ETradeTypeInstrument.Stock;

    if (this.isLot) {
      if (this.orderQuantity < 100) {
        this.alert = OrderErrors.FractionalMaxSize;
        this.setToFractionary();
        return false;
      }
      if (this.orderQuantity % 100 !== 0) {
        this.alert = OrderErrors.LotMaxMessage;
        return false;
      }
    }
    if (isStockType && !this.isLot && this.orderQuantity > 99) {
      this.alert = OrderErrors.LotMessage;
      this.setToStandard();
      return false;
    }
    return true;
  }

  private validateOrderQuantity() {
    if (this.type === OrderType.Sell && this.validateSell()) {
      this.error = null;
    }
    if (this.type === OrderType.Buy && this.validateBuy()) {
      this.error = null;
    }
  }

  @Watch('orderQuantity')
  checkOrderErrorsAfterChangeQuantity() {
    if (this.validateStock()) {
      this.alert = null;
    }
    this.validateOrderQuantity();
    if (
      this.error === OrderErrors.BuyMessage &&
      this.priceTotal <= this.currentBalanceAvailable?.value!
    ) {
      this.error = null;
    }
  }

  private get priceTotal() {
    return this.priceMarket * this.orderQuantity;
  }

  private setToStandard() {
    const instrument = this.instrumentTitle;
    this.actionLabel = this.isLot
      ? instrument
      : instrument.substring(0, instrument.length - 1);
  }

  private setToFractionary() {
    this.actionLabel = `${this.instrumentTitle}F`;
  }

  private displayValue(value: number) {
    return money(value);
  }

  private get instrumentInAuction() {
    return this.instrumentStatus && this.instrumentStatus.message;
  }

  private get instrumentInAuctionMessage() {
    return this.instrumentStatus.message;
  }

  private get isSubscriptionInstrument() {
    return (
      this.instrument.type!.value === ETradeTypeInstrument.SubscriptionReceipt
    );
  }
}
