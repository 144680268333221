import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { StockEvent } from '@/modules/wealth/services/portfolio';

export const columns = [
  {
    align: 'left',
    width: '12%',
    getter: (profit: StockEvent) => profit.name,
    label: 'Ativo'
  },
  {
    align: 'right',
    width: '44%',
    component: MoneyCell,
    getter: (profit: StockEvent) => profit.amount.formatted,
    label: 'Valor'
  },
  {
    align: 'right',
    width: '44%',
    getter: (profit: StockEvent) => profit.type,
    label: 'Tipo'
  }
];
