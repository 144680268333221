import { render, staticRenderFns } from "./PerformanceLink.vue?vue&type=template&id=2f233536&scoped=true&"
import script from "./PerformanceLink.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PerformanceLink.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PerformanceLink.vue?vue&type=style&index=0&id=2f233536&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f233536",
  null
  
)

export default component.exports