import { render, staticRenderFns } from "./AdvisorStepMobile.vue?vue&type=template&id=3a6f947d&scoped=true&"
import script from "./AdvisorStepMobile.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AdvisorStepMobile.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AdvisorStepMobile.vue?vue&type=style&index=0&id=3a6f947d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a6f947d",
  null
  
)

export default component.exports