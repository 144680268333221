import { render, staticRenderFns } from "./AnnualProfit.vue?vue&type=template&id=0123b462&scoped=true&"
import script from "./AnnualProfit.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AnnualProfit.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AnnualProfit.vue?vue&type=style&index=0&id=0123b462&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0123b462",
  null
  
)

export default component.exports