import {
  ActionTypes,
  DashStatusButtonActions
} from '@/modules/trade/types/dash-status';
import { IDashButtons } from '@/modules/trade/services/account';

/**
 * Remove os botões que sua ação depende de saber qual é o portfolio api id da Bolsa(id da carteira da Bolsa).
 */
export function filtersPortfolioApiIdDependentButtons(buttons: IDashButtons[]) {
  const portfolioApiIdDependentButtonActions = [
    DashStatusButtonActions.Withdrawal,
    DashStatusButtonActions.Deposit,
    DashStatusButtonActions.DepositConversation,
    DashStatusButtonActions.Transaction,
    ActionTypes.StartTradeDeposit,
    ActionTypes.FirstTradeDeposit,
    ActionTypes.StartGenericDeposit,
    ActionTypes.StartTradeTransaction,
    ActionTypes.StartCashDeposit
  ];

  return buttons.filter(
    button =>
      !portfolioApiIdDependentButtonActions.includes(
        button.action as DashStatusButtonActions
      )
  );
}
