import { httpClient } from '@/modules/common/services/http/http-client';
import { IInstrumentDetails } from './types/models/IBffInvestments';

const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}`
});

export function getInstrumentDetails(
  apiId: string
): Promise<IInstrumentDetails> {
  return http.get(`/instruments/v1/instrument/${apiId}/details`);
}
