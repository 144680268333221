export function toFixedWithoutRounding(
  value: number,
  decimals = 2,
  withComma = false
): string {
  const [number, numberDecimals = ''] = (value || 0).toString().split('.');

  const truncatedDecimals = numberDecimals.slice(0, decimals);
  const formattedDecimals = truncatedDecimals.padEnd(decimals, '0');
  const fixedNumber = [number, formattedDecimals].join(withComma ? ',' : '.');

  return fixedNumber;
}
