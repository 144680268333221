import FinishedFormStep from '../components/FinishedFormStep/FinishedFormStep.vue';
import InitialFormStep from '../components/InitialFormStep/InitialFormStep.vue';
import PasswordFormStep from '../components/PasswordFormStep/PasswordFormStep.vue';
import { SignUpFormSteps } from '../types';

export const SignUpFormStepComponents = {
  [SignUpFormSteps.Initial]: InitialFormStep,
  [SignUpFormSteps.Password]: PasswordFormStep,
  [SignUpFormSteps.Finished]: FinishedFormStep
};
