import { httpClient } from '@/modules/common/services/http/http-client';
import {
  GetOrdersParams,
  GetOrdersResponse,
  GetOrdersSummaryResponse,
  GetOrdersSummaryParams,
  ChangePortabilityAveragePriceResponse,
  OrderDetailsResponse,
  GetCustomerOrdersInstrumentsResponse,
  GetCustomerOrdersInstrumentsParams
} from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trade/`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function getOrders(
  params: GetOrdersParams,
  abortSignal?: AbortSignal
): Promise<GetOrdersResponse> {
  return httpClientWithDefaultOptions.get('v1/orders', {
    params,
    abortSignal
  });
}

export function getOrdersSummary(
  params: GetOrdersSummaryParams
): Promise<GetOrdersSummaryResponse> {
  return httpClientWithDefaultOptions.get('v1/orders/summary', {
    params
  });
}

export function getOrderDetails(
  id: string,
  abortSignal?: AbortSignal
): Promise<OrderDetailsResponse> {
  return httpClientWithDefaultOptions.get(`v2/order/${id}`, {
    abortSignal
  });
}

export function changePortabilityAveragePrice(
  id: string,
  averagePrice: number,
  abortSignal?: AbortSignal
): Promise<ChangePortabilityAveragePriceResponse> {
  return httpClientWithDefaultOptions.put(
    `v1/update-quote-value`,
    {
      orderId: id,
      quoteValue: averagePrice
    },
    {
      abortSignal
    }
  );
}

export function getCustomerOrdersInstruments(
  params: GetCustomerOrdersInstrumentsParams,
  abortSignal?: AbortSignal
): Promise<GetCustomerOrdersInstrumentsResponse> {
  return httpClientWithDefaultOptions.get('v1/customer-instruments', {
    params,
    abortSignal
  });
}
