import { MoneyCell } from '@/modules/common/components/MoneyCell';
import { ProvisionedProfit } from '@/modules/wealth/services/portfolio';

export const columns = [
  {
    align: 'left',
    width: '12%',
    getter: (profit: ProvisionedProfit) => profit.name,
    label: 'Ativo'
  },
  {
    align: 'right',
    width: '22%',
    component: MoneyCell,
    getter: (profit: ProvisionedProfit) => profit.amount.formatted,
    label: 'Valor'
  },
  {
    align: 'right',
    width: '22%',
    getter: (profit: ProvisionedProfit) => profit.type,
    label: 'Tipo'
  },
  {
    align: 'right',
    width: '22%',
    getter: (profit: ProvisionedProfit) => String(profit.quantity),
    label: 'Quantidade'
  },
  {
    align: 'right',
    width: '22%',
    getter: (profit: ProvisionedProfit) => profit.expectedPaymentDate,
    label: 'Pagamento em'
  }
];
