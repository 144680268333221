import { IDynamicContent } from '@/modules/common/types';
import {
  FilterData,
  PaginationData,
  InstrumentImage,
  NumberFormatted,
  StringFormatted,
  OrderStatus,
  InstrumentTypeFormatted,
  Formatted
} from '@/modules/trade/types';
import { ValueFormatted } from '@/modules/transaction/types';

export interface AssetType {
  allocatedTotal: NumberFormatted;
  color?: string;
  assetType: StringFormatted;
  percentage: NumberFormatted;
}

export interface PositionsChart {
  amountAssetsTotal: number;
  allocatedTotal: NumberFormatted;
  assetTypes: Array<AssetType>;
}

export interface GetPositions<T> {
  items: T[];
  filters: FilterData;
  pagination: PaginationData;
}

export interface GetPositionsResponse extends GetPositions<Instrument> {
  updatedAt?: NumberFormatted;
}

export interface PositionsParams {
  type: string | number;
  page: number;
  itemsPerPage: number;
}

export interface Instrument {
  symbol: string;
  name: string | null;
  quantity: number;
  image: InstrumentImage | null;
  averagePrice: NumberFormatted;
  profitPrice: NumberFormatted;
  profitPercentageChange: NumberFormatted;
  balance: NumberFormatted;
  lastPrice: NumberFormatted;
  dailyPercentageChange: NumberFormatted;
  type: InstrumentTypeFormatted | null;
}

export interface InstrumentWithWatchlist {
  symbol: string;
  name: string | null;
  image: InstrumentImage | null;
  watchlistItemId: string | null;
  lastPrice: NumberFormatted;
  dailyPercentageChange?: NumberFormatted;
  type: InstrumentTypeFormatted | null;
}

export interface GetWatchlistResponse {
  items: InstrumentWithWatchlist[];
  pagination: PaginationData;
}

export interface GetWatchlistParams {
  page: number;
  itemsPerPage: number;
}

export interface IInstrumentAbout {
  title: string;
  description: string;
  image: {
    small: string;
    medium: string;
    large: string;
  };
  name: {
    value: string;
    description: string;
  };
  document?: {
    value: string;
    description: string;
  };
  segment: {
    value: string;
    description: string;
  };
  type: {
    value: string;
    description: string;
  };
  managementType?: {
    value: string;
    description: string;
  };
  administrator?: {
    value: string;
    description: string;
  };
}

export interface IInstrumentView {
  about: IInstrumentAbout;
}

export enum OrderDirection {
  Buy = 'Buy',
  Sell = 'Sell'
}

export interface OrderStatusMessage {
  description: string;
  label: string;
  title: string;
}

export interface OrderStatusFormatted {
  value: OrderStatus;
}

export interface CreateOrderFeedback {
  statusMessage: OrderStatusMessage;
  status: OrderStatusFormatted;
}

export interface CreateOrderBody {
  symbol: string;
  price: number;
  quantity: number;
  orderDirection: OrderDirection;
}

export interface PositionsView<T> {
  paginatedPositions: GetPositions<T>;
  chart: PositionsChart;
  updatedAt?: NumberFormatted;
}

export interface Profit {
  percentage: NumberFormatted;
  amount: NumberFormatted;
}

export interface InstrumentHistorical {
  symbol: string;
  name: string | null;
  quantity: number;
  image: InstrumentImage | null;
  balance: NumberFormatted;
  lastPrice: NumberFormatted;
  type: StringFormatted;
  monthlyProfits: Profit;
  inceptionProfits: Profit;
}

export interface PositionSummaryError {
  title: string;
  subtitle: string;
}

export interface PositionSummaryContent {
  balance: NumberFormatted;
  quantity: NumberFormatted;
}

export enum PositionsGroups {
  DERIVATIVES = 'derivativePosition',
  STOCK_LENDING = 'btcPosition',
  CASH_MARKET = 'position',
  CUSTOM_OPERATIONS = 'customOperationPosition'
}

export interface PositionSummary {
  description: Formatted<PositionsGroups>;
  error?: PositionSummaryError;
  content?: PositionSummaryContent;
}

export type PositionsSummaryResponse = PositionSummary[];

export interface GetStockLendingListParams {
  page: number;
  itemsPerPage: number;
  side?: string | null;
}

export interface StockLendingFilters {
  selected: string;
  available: StringFormatted[];
}

export interface StockLendingPosition {
  symbol: string;
  name: string | null;
  type: InstrumentTypeFormatted | null;
  side: StringFormatted;
  originalQuantity: number;
  currentQuantity: number;
  basePrice: NumberFormatted;
  fee: NumberFormatted;
  initialDate: StringFormatted;
  expirationDate: StringFormatted;
}

export interface GetStockLendingListResponse {
  pagination: PaginationData;
  updatedAt: StringFormatted;
  filters: StockLendingFilters;
  items: StockLendingPosition[];
}

export interface OptionPosition {
  symbol: string;
  name: string | null;
  instrumentType: InstrumentTypeFormatted | null;
  optionType: StringFormatted;
  quantity: number;
  dueDate: StringFormatted;
  strikePrice: NumberFormatted;
  currentPrice: NumberFormatted;
  balance: NumberFormatted;
}

export interface GetOptionsListResponse {
  items: OptionPosition[];
  updatedAt: NumberFormatted;
  pagination: PaginationData;
}

export interface GetOptionsListParams {
  page: number;
  itemsPerPage: number;
}

export interface ForwardContractPosition {
  symbol: string;
  name: string | null;
  instrumentType: InstrumentTypeFormatted | null;
  quantity: number;
  dueDate: StringFormatted;
  forwardPrice: NumberFormatted;
  currentPrice: NumberFormatted;
  positionPrice: NumberFormatted;
}

export interface GetForwardListResponse {
  items: ForwardContractPosition[];
  updatedAt: NumberFormatted;
  pagination: PaginationData;
}

export interface GetForwardListParams {
  page: number;
  itemsPerPage: number;
}

export interface CustomOperationPosition {
  id: string;
  symbol: string;
  instrumentType: InstrumentTypeFormatted | null;
  quantity: number;
  strategy: StringFormatted;
  dueDate: StringFormatted;
  initialDate: StringFormatted;
  initialPrice: NumberFormatted;
  currentPrice: NumberFormatted;
  expiryCountdown: StringFormatted;
  totalEarnings: NumberFormatted;
}

export interface GetCustomOperationsListResponse {
  items: CustomOperationPosition[];
  updatedAt: NumberFormatted;
  pagination: PaginationData;
}

export interface GetCustomOperationsListParams {
  page: number;
  itemsPerPage: number;
}

export interface HistoricalOptionPosition {
  symbol: string;
  instrumentType: InstrumentTypeFormatted | null;
  name: string | null;
  optionType: StringFormatted;
  quantity: number;
  dueDate: StringFormatted;
  strikePrice: NumberFormatted;
  result: Profit;
  maximumGain: NumberFormatted;
  maximumLoss: NumberFormatted;
  balance: NumberFormatted;
}

export interface GetHistoricalOptionsListResponse {
  items: HistoricalOptionPosition[];
  updatedAt: NumberFormatted;
  pagination: PaginationData;
}

export interface GetHistoricalOptionsListParams {
  page: number;
  itemsPerPage: number;
}

export interface GetInstrumentPositionHistoricalResponse {
  inceptionProfits: Profit;
  monthlyProfits: Profit;
  paidEvents: NumberFormatted;
  updatedAt: NumberFormatted;
  price: NumberFormatted;
  currentValue: NumberFormatted;
  quantity: number;
  symbol: string;
}

export interface AxisXRange {
  start: StringFormatted;
  end: StringFormatted;
}

export interface InstrumentChartAxisX {
  description: string;
  range: AxisXRange;
  values: StringFormatted[];
}

export interface InstrumentChartAxisY {
  description: string;
  values: NumberFormatted[];
}

export interface InstrumentChartFilters {
  value: string;
  formatted: string;
  selected: boolean;
}

export interface InstrumentChart {
  axisY: InstrumentChartAxisY[];
  axisX: InstrumentChartAxisX[];
  periodFilters: InstrumentChartFilters[];
  updatedAt: NumberFormatted;
}

export interface GetInstrumentChartResponse {
  chart: InstrumentChart;
}

export interface InstrumentPriceMessage {
  title: string;
  description: string;
}

export interface InstrumentPrice {
  symbol: string;
  date: string;
  price: NumberFormatted;
  bestOfferSell: NumberFormatted;
  bestOfferBuy: NumberFormatted;
  dailyPercentualChange: NumberFormatted;
  message: InstrumentPriceMessage;
  updatedAt: NumberFormatted;
  favorited: boolean;
}

export interface InstrumentStatus {
  symbol: string;
  lotSize: number;
  maxQuantity: number;
  minQuantity: number;
  type: { value: ETradeTypeInstrument; formatted: string };
  riskProfile: RiskProfile;
  negotiationStatus: NegotiationStatus;
}

export enum ETradeTypeInstrument {
  Stock = 'Stock',
  Crypto = 'Crypto',
  Fii = 'FII',
  Bdr = 'BDR',
  Etf = 'ETF',
  SubscriptionReceipt = 'SubscriptionReceipt',
  Option = 'Option',
  Index = 'Index'
}

export interface RiskProfile {
  customerRiskTolerance: { value: string; formatted: string };
  instrumentRiskTolerance: { value: string; formatted: string };
}

export interface NegotiationStatus {
  message?: string;
  buttons: NegotiationStatusButton[];
}

export interface NegotiationStatusButton {
  action: string;
  state: string;
  title: string;
}

export interface AcceptOutsideRiskProfileTerm {
  hasSigned: boolean;
}

export interface ValidateOrderBody {
  symbol: string;
  quantity: number;
  orderDirection: OrderDirection;
}

export interface ValidateOrderResponse {
  status: string;
  order: {
    symbol: string;
    description: string;
    type: string;
    price: ValueFormatted;
    quantity: number;
    orderTotal: ValueFormatted;
    brokerageFee: ValueFormatted;
    brokerageDisclaimer: IDynamicContent[];
  };
}
