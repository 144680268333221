import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=1ad90f6d&scoped=true&"
import script from "./OrderDetails.vue?vue&type=script&setup=true&lang=ts&"
export * from "./OrderDetails.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=1ad90f6d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad90f6d",
  null
  
)

export default component.exports