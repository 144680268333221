import { GtmTracker } from '@/modules/common/services/analytics';

const trackSignUpEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signup',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackPageView() {
  trackSignUpEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackSendForm() {
  trackSignUpEvent({
    event_type: 'click',
    event_name: 'send_form',
    is_conversion: true
  });
}

export function trackSelectAdvisorExistence() {
  trackSignUpEvent({
    event_type: 'click',
    event_name: 'select_advisor_existence'
  });
}

export function trackFillName() {
  trackSignUpEvent({
    event_type: 'blur',
    event_name: 'fill_name'
  });
}

export function trackFillEmail() {
  trackSignUpEvent({
    event_type: 'blur',
    event_name: 'fill_email'
  });
}

export function trackFillCpf() {
  trackSignUpEvent({
    event_type: 'blur',
    event_name: 'fill_cpf'
  });
}

export function trackFillPhone() {
  trackSignUpEvent({
    event_type: 'blur',
    event_name: 'fill_phone'
  });
}

const trackSignUpAdvisorEmail = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signup_advisor_email',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackAdvisorModalView() {
  trackSignUpAdvisorEmail({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackFillAdvisorEmail() {
  trackSignUpAdvisorEmail({
    event_type: 'blur',
    event_name: 'fill_advisor_email'
  });
}

export function trackSendAdvisorEmail() {
  trackSignUpAdvisorEmail({
    event_type: 'click',
    event_name: 'send_advisor_email'
  });
}

const trackSignUpPassword = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signup_password',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackSignupPasswordView() {
  trackSignUpPassword({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackFillPassword() {
  trackSignUpPassword({
    event_type: 'blur',
    event_name: 'fill_password'
  });
}

export function trackFillConfirmPassword() {
  trackSignUpPassword({
    event_type: 'blur',
    event_name: 'fill_confirm_password'
  });
}

export function trackSendPassword() {
  trackSignUpPassword({
    event_type: 'click',
    event_name: 'send_password'
  });
}

const trackSignUpQrCode = GtmTracker.trackerWithDefaultProps({
  page_screen: 'signup_qr_code',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackQrCodeView() {
  trackSignUpQrCode({
    event_type: 'load',
    event_name: 'view'
  });
}
