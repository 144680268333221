
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  FilterFoundation,
  BadgeCounterFoundation,
  BaseIcon
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    FilterFoundation,
    BadgeCounterFoundation,
    BaseIcon
  }
})
export default class FilterSelectButton extends Vue {
  @Prop({ type: Number, default: 0, required: false })
  readonly selectedCounter!: number;

  @Prop({ type: String, required: true })
  readonly label!: string;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  readonly showChevronIcon!: boolean;

  @Emit('click')
  emitClickEvent() {}

  get isActive() {
    return this.selectedCounter > 0;
  }

  handleClick() {
    if (this.disabled) return;
    this.emitClickEvent();
  }
}
