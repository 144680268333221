import { render, staticRenderFns } from "./CostsDetails.vue?vue&type=template&id=1a5b1205&scoped=true&"
import script from "./CostsDetails.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CostsDetails.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CostsDetails.vue?vue&type=style&index=0&id=1a5b1205&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5b1205",
  null
  
)

export default component.exports