
import { Component, Prop, Emit } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ButtonPrimary,
  NavBar,
  BaseIcon,
  ContextColorProvider,
  BaseText,
  Heading,
  HelperView,
  ChipContext
} from '@warrenbrasil/nebraska-web';

import { RouteMixin } from '@/modules/common/mixins/route';
import { ICustomer } from '@/types/models';
import {
  isOrchestratorActive,
  goToNextRoute
} from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';

import { getMyUserProfile } from '../../services/api';
import { IUserProfile, UserProfileError } from '../../types';
import { CloseButton } from '@/modules/common/components/buttons';
import UserProfileSkeleton from '../UserProfileSkeleton/UserProfileSkeleton.vue';
import {
  trackUserProfileButtonClick,
  trackUserProfileCloseButton,
  trackUserProfileErrorClick,
  trackUserProfileErrorView,
  trackUserProfilePageView
} from '../../helpers/track-events';

enum UserProfileAction {
  GO_TO_SUITABILITY = 'go_to_suitability',
  GO_TO_REGISTER = 'go_to_register',
  GO_TO_REGISTER_REGULATORY_UPDATE = 'go_to_register_regulatory_update',
  GO_TO_HOME = 'go_to_home'
}

@Component({
  components: {
    ContextColorProvider,
    CloseButton,
    NavBar,
    BaseIcon,
    ButtonPrimary,
    BaseText,
    Heading,
    UserProfileSkeleton,
    HelperView,
    ChipContext
  }
})
export default class UserProfile extends mixins(RouteMixin) {
  @Prop({ type: Object })
  private user?: ICustomer;

  public readonly NebraskaColors = NebraskaColors;

  public userProfile!: IUserProfile;
  public loadedUserProfile = false;
  public error = false;

  async mounted() {
    try {
      this.userProfile = await getMyUserProfile(this.user!._id!);
      this.loadedUserProfile = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.loaded();
      });
      trackUserProfilePageView();
    } catch (error) {
      trackUserProfileErrorView();
      const userProfileError = error as UserProfileError;
      const fallbackAction = userProfileError.error.fallbackAction;
      if (fallbackAction) {
        fallbackAction === UserProfileAction.GO_TO_SUITABILITY &&
          this.$router.push({
            name: 'home',
            query: { suitabilityModal: 'true' }
          });
        this.handleUserProfileAction(fallbackAction);
        return;
      }
      this.error = true;
    }
  }

  public buttonAction(action: UserProfileAction | string) {
    let eventName = 'redo_discovery';
    if (this.userProfile.navigation.title === 'Continuar') {
      eventName = 'continue';
    }
    trackUserProfileButtonClick(eventName);
    this.handleUserProfileAction(action);
  }

  public openSuitabilityModal() {
    this.$router.push({
      query: {
        ...this.$route.query,
        suitabilityModal: 'true'
      }
    });
  }

  private handleUserProfileAction(action: string) {
    if (isOrchestratorActive()) {
      return goToNextRoute();
    }

    switch (action) {
      case UserProfileAction.GO_TO_SUITABILITY:
        return this.openSuitabilityModal();
      case UserProfileAction.GO_TO_REGISTER:
        return this.openSuitabilityModal();
      case UserProfileAction.GO_TO_REGISTER_REGULATORY_UPDATE:
        return this.goToEditAccount();
      case UserProfileAction.GO_TO_HOME:
        return this.goToHome();
    }
  }

  private goToHome() {
    this.goToPath('/app/#/home');
  }

  private goToEditAccount() {
    this.goToPath('/app/#/user/account');
  }

  public closeButtonAction() {
    trackUserProfileCloseButton();
    if (this.shouldGoBack) this.$router.back();
    else this.goToHome();
  }

  get shouldGoBack() {
    return !!this.$route.query.goBack;
  }

  public get mainButtonAction() {
    return this.userProfile?.navigation.action ?? '';
  }

  public get isUserB2B() {
    return this.user?.clientType?.whitelabel || this.user?.clientType?.partner;
  }

  public get iconColor() {
    return this.isUserB2B
      ? NebraskaColors.neutralPrimary
      : NebraskaColors.brandPrimary;
  }

  public get backgroundIconColor() {
    return this.isUserB2B
      ? NebraskaColors.neutralSecondary
      : NebraskaColors.brandSecondary;
  }

  public get hasProducts() {
    return !!this.userProfile.products && this.userProfile.products.length;
  }

  public retrySuitabilityProfile() {
    trackUserProfileErrorClick();
    window.location.reload();
  }

  @Emit()
  private loaded() {}
}
