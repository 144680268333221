
import { getYear } from 'date-fns';
import { Component, Vue } from 'vue-property-decorator';
import {
  NavBar,
  BaseIcon,
  HelperView,
  Row,
  ContextColorProvider,
  Heading
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { getString } from '@/modules/common/helpers/resources';
import { getFirstPortfolioAge } from '@/modules/cash/services/costs';
import { RouteMixin } from '@/modules/common/mixins/route';
import { CloseButton } from '@/modules/common/components/buttons';

import { MonthlyCostsList } from './components/MonthlyCostsList';
import { CashbackSection } from './components/CashbackSection';
import { YearOption } from './types';
import { trackViewLoad, trackFAQClick, trackNavbarClose } from './track-events';

@Component({
  name: 'Costs',
  components: {
    NavBar,
    CloseButton,
    HelperView,
    MonthlyCostsList,
    CashbackSection,
    ContextColorProvider,
    Row,
    BaseIcon,
    Heading
  },
  mixins: [RouteMixin]
})
export default class Costs extends Vue {
  private year: YearOption = {
    label: String(getYear(new Date())),
    value: getYear(new Date())
  };

  private years: YearOption[] = [];

  public NebraskaColors = NebraskaColors;
  public getString = getString;
  public isError = false;
  public isLoading = true;

  public mounted() {
    trackViewLoad();
    this.getYearOptions();
  }

  public async getYearOptions() {
    this.isError = false;
    this.isLoading = true;

    try {
      const { value } = await getFirstPortfolioAge();
      this.setYearsOptions(value);
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public setYearsOptions(yearsBefore: number) {
    this.years = [this.year];

    for (let i = 1; i <= yearsBefore; i++) {
      const year = this.year.value - i;
      this.years.push({ label: String(year), value: year });
    }
  }

  public reload() {
    this.getYearOptions();
  }

  public goToCostsFAQ() {
    trackFAQClick();
    this.$router.push({ name: 'help-center' });
  }

  private close() {
    trackNavbarClose();
    this.$router.push({ name: 'home' });
  }
}
