import { YupValidation } from '@/modules/common/helpers/yup-validation';
import { object, string } from 'yup';

export enum EmailErrors {
  Required = 'Precisamos de um e-mail válido.',
  Invalid = 'Ops! E-mail inválido'
}

export const signinEmailSchema = object().shape({
  email: string().required(EmailErrors.Required).email(EmailErrors.Required)
});

const emailStepSchema = object().shape({
  email: string().required(EmailErrors.Required).email(EmailErrors.Invalid)
});

export function getEmailStepValidator() {
  return new YupValidation(emailStepSchema);
}
