import { EProductsAcquisitionSteps } from './types';

import { ProductList } from '../components/ProductList';
import { ProductReview } from '../components/ProductReview';
import { Conclusion } from '../components/Conclusion';

export const ProductsAcquitionComponents = {
  [EProductsAcquisitionSteps.PRODUCTS]: ProductList,
  [EProductsAcquisitionSteps.REVIEW]: ProductReview,
  [EProductsAcquisitionSteps.CONCLUSION]: Conclusion
};
