export enum SignUpFormSteps {
  Initial = 'initial',
  Password = 'password',
  Finished = 'finished'
}

export enum InputStatusType {
  Default = 'default',
  Error = 'error',
  Success = 'success'
}

export interface ProspectSignUp {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  advisorEmail?: string;
}

export interface ProspectPassword {
  email: string;
  password: string;
}

export interface ProspectPasswordForm {
  password: string;
  confirmPassword: string;
}

export interface PasswordValidation {
  isValid: boolean;
  tip: string;
  status: InputStatusType;
  progress: number;
  color: string;
}

export interface IPasswordValidationRequest {
  password: string;
}

export interface IPasswordValidationResponse {
  isValid: boolean;
  score: number;
  tip: string;
}

export interface YupError {
  key: string;
  value: string;
}

export interface Advisor {
  id: string;
  name: string;
  image: string;
  email: string;
}

export interface SignUpError {
  error: {
    code: number;
    requestId: string;
    key: string;
    path: string;
    message: {
      value: string;
      formatted: string;
    };
    locations: Array<{
      message: {
        value: string;
        formatted: string;
      };
    }>;
  };
}

export interface IErrorModalContent {
  title: string;
  description: string;
  showButtonToLogin: boolean;
}
