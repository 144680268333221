
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  HeadingDisplay,
  ButtonContainer,
  ButtonPrimary,
  InputMoney
} from '@warrenbrasil/nebraska-web';
import { getInvestmentProfile, saveInvestmentProfile } from '../../services';
import { InputStatusType, InvestmentProfile } from '../../types';
import yupValidation from './validate';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import { parseApiErrorToScreenError } from '../../helpers/parse-api-error-to-screen-error';
import {
  trackEvent,
  trackContinue,
  trackError,
  trackView
} from './track-events';
import { EventKnownProperties } from '@warrenbrasil/web-analytics';

@Component({
  components: { HeadingDisplay, ButtonContainer, ButtonPrimary, InputMoney }
})
export default class RegulatoryUpdateInvestmentProfile extends Vue {
  @Prop({ type: Boolean, required: true })
  public showContent!: boolean;

  @Emit()
  private setIsLoading(_isLoading: boolean) {}

  @Emit()
  private setHasError(_hasError: boolean) {}

  @Emit()
  private goToNextPage() {}

  @Emit()
  private notifyError(_message?: string) {}

  @Emit()
  private changeTracker(
    _newTracker: (remainingProps: EventKnownProperties) => void
  ) {}

  public investmentProfile: InvestmentProfile | null = null;

  public isButtonLoading = false;

  public errors: YupValidationErrors = {};

  mounted() {
    this.fetchInvestmentProfile();
    this.changeTracker(trackEvent);
  }

  private async fetchInvestmentProfile() {
    this.setIsLoading(true);
    this.setHasError(false);

    try {
      this.investmentProfile = await getInvestmentProfile();
      trackView();
    } catch (_e) {
      this.setHasError(true);
    }

    this.setIsLoading(false);
  }

  public getInputStatus(key: string) {
    return this.errors[key] ? InputStatusType.Error : InputStatusType.Default;
  }

  public getErrorMessage(key: string) {
    return this.errors[key] ? this.errors[key][0] : '';
  }

  public removeError(key: string) {
    delete this.errors[key];
  }

  public async handleButtonClick() {
    await this.validateErrors();

    if (yupValidation.isValid()) {
      this.isButtonLoading = true;
      try {
        await saveInvestmentProfile(this.investmentProfile!);
        trackContinue();
        this.goToNextPage();
      } catch (e: any) {
        if (e?.error?.message?.value === 'InvalidInput') {
          this.errors = parseApiErrorToScreenError(e.error.locations);
        } else if (e?.error?.message?.value === 'ExpiredPassword') {
          this.$router.push({
            name: 'regulatoryUpdateVerifyPassword',
            query: { cameFrom: 'regulatoryUpdateInvestmentProfile' }
          });
        } else {
          trackError(e?.error?.message?.formatted);
          this.notifyError(e?.error?.message?.formatted);
        }
      }
      this.isButtonLoading = false;
    }

    Object.values(this.errors).forEach((v: string[]) => trackError(v[0]));
  }

  private async validateErrors() {
    try {
      await yupValidation.validate(this.investmentProfile);
    } catch (_e) {}

    this.errors = yupValidation.getAllErrors();
  }
}
