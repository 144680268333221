import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IPortfolioSheet,
  IPeriod,
  IRisk,
  ICreatePortfolio,
  IInvestmentProfile
} from './funds-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/portfolios`
});

export function getPortfolioSheet(
  portfolioId: string,
  type: string,
  investorType = 'Common'
): Promise<IPortfolioSheet> {
  return http.get(`/v1/catalog/${portfolioId}/sheet`, {
    params: {
      type,
      investorType
    }
  });
}

export function getPeriods(): Promise<IPeriod[]> {
  return http.get(`/v1/catalog/periods`);
}

export function getRisks(params: {
  investorType: string;
  type?: string;
  period?: string;
}): Promise<IRisk[]> {
  return http.get(`/v2/catalog/risks`, { params });
}

export function createPortfolio(params: {
  managedPortfolioId: string;
  name: string;
  type: string;
  investorType?: string;
}): Promise<ICreatePortfolio> {
  return http.post(`/v2/catalog/createPortfolio`, { ...params });
}

export function getInvestmentProfiles(): Promise<IInvestmentProfile[]> {
  return http.get(`/v1/suggestions/investor-type-options`);
}
