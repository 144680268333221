
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputMoney } from '@warrenbrasil/nebraska-web';

import { getString } from '@/modules/common/helpers/resources';
import { IProfessionalProfile } from '@/types/models/Customer/ProfessionalProfile';

export enum InputStatusType {
  Default = 'default',
  Error = 'error'
}

@Component({ components: { InputMoney } })
export default class FinancialDataForm extends Vue {
  @Prop({ required: true })
  public investmentProfile!: IProfessionalProfile;

  public getString = getString;

  public depositFieldStatus = InputStatusType.Default;

  public mounted() {
    if (this.investmentProfile.movableProperty === undefined) {
      this.$set(this.investmentProfile, 'movableProperty', 0);
    }
    if (this.investmentProfile.privatePension === undefined) {
      this.$set(this.investmentProfile, 'privatePension', 0);
    }
    if (this.investmentProfile.realEstate === undefined) {
      this.$set(this.investmentProfile, 'realEstate', 0);
    }
    if (this.investmentProfile.totalInvested === undefined) {
      this.$set(this.investmentProfile, 'totalInvested', 0);
    }
  }
}
